import React, { Component } from "react";
import { winkActions } from "../../actions/winkActions";
import { connect } from "react-redux";
import { history } from "../app/App.js";
import { utils } from "../../helper/utils.js";
import Loader from "../Loader/Loader.js";
import { default as LOCALE } from "../../localization/index.js";
import { userConstants } from "../../constants/userConstants.js";
import { winksConstants } from "../../constants/winksConstants";

import Tab from "../tab/tab.js";
import SideDrawer from "../SideDrawer/SideDrawer.js";

class ViewWinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allwinks: null,
    };
  }

  componentDidMount() {
    this.props.getReceivedWinksList();
  }

  handleImageClick = (userProfileId) => {
    history.push(`/users/${userProfileId}`);
  };

  // drawerToggleClickHandler = () => {
  //   this.setState((prevState) => {
  //     return { sideDrawerOpen: !prevState.sideDrawerOpen };
  //   });
  // };

  // dismissSideDrawer = () => {
  //   this.setState({ sideDrawerOpen: false });
  // };

  render() {
    LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
    console.log("gift props", this.props);
    const {
      gettingWinks,
      receivedwinks,
      invalidData,
      internalError,
      errorMessage,
    } = this.props.getReceivedWinksListReducer;

    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    console.log("searchResults render receivedwinks=", receivedwinks);

    if (receivedwinks.length > 0) {
      console.log("winks new", this.state.allwinks);

      return (
        <div>
          <div className="home-page-wrapper search-result">
            <div className="home-page">
              <div className="top-bar">
                <img
                  onClick={() => {
                    history.goBack();
                  }}
                  src={require("../../img/back.png")}
                  alt="back-button"
                />
                <h3>{LOCALE.winks}</h3>
              </div>

              <div className="scrollx" onScroll={this.paginateIfNecessary}>
                {receivedwinks?.map((wink, i) => (
                  <div
                    onClick={this.handleImageClick.bind(this, wink.id)}
                    key={i}
                  >
                    <div className="searched-image">
                      <div className="searched-img">
                        <img
                          key={i}
                          className="img-search"
                          src={wink.image_path.image_250}
                          alt="user"
                        />
                      </div>
                      <div className="searched-det">
                        <label>{`${wink.name}`}</label>
                        <br />
                        <label>{wink.wink_message} </label>
                        {/* {wink.user_state_name !== "None" ? (
                          <label>{wink.user_state}, </label>
                        ) : (
                          ""
                        )} */}
                        <div>
                          <p>
                            {new Date(wink.sent_date_time).toLocaleString(
                              "en-US",
                              options
                            )}
                          </p>
                        </div>
                      </div>
                      <div class="line"></div>
                    </div>
                  </div>
                ))}
              </div>

              {gettingWinks && <Loader />}

              {/* <div>
                Back to Profile?
                <button
                  className="btn-clear top-space"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <strong>Click here</strong>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      );
    }
    if (gettingWinks) {
      return (
        <div className="home-page-wrapper">
          <div className="home-page">
            <div className="top-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>{LOCALE.winks}</h3>
            </div>
            <Loader />
          </div>
        </div>
      );
    } else {
      return (
        <div className="home-page-wrapper friends-list">
          <div className="home-page">
            <div className="top-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>{LOCALE.winks}</h3>
            </div>
            <div className="homepage-content">
              <h2>{LOCALE.no_matches_yet}</h2>
              {gettingWinks && <Loader />}
              {internalError && <div>{LOCALE.internal_error}</div>}
              {invalidData && <div>{errorMessage}</div>}
              {/* <button
                className="btn00"
                onClick={() => {
                  history.push("/search");
                }}
              >
                {" "}
                {LOCALE.searchWinks}
              </button> */}
              {/*
                            <div>Back to Profile?
                                <button className="btn-clear top-space"
                                        onClick={() => {
                                            history.goBack();
                                        }}><strong>Click here</strong>
                                </button>
                            </div>*/}
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapState = (state) => {
  return {
    getReceivedWinksListReducer: state.getReceivedWinksListReducer,
  };
};
const actionCreators = {
  getReceivedWinksList: winkActions.getReceivedWinksList,
};
export default connect(mapState, actionCreators)(ViewWinks);
