import { apiService } from "../services/apiService";
import { countryConstants } from "../constants/countryConstants";
import { history } from "../components/app/App";
import { utils } from "../helper/utils";

export const countryActions = {
  getCountries,
  getStates,
  getCities,
};

function getCountries() {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getCountries()
      .then((response) => {
        console.log("viewCountries responseData=", response);

        // utils.addCountryStateCityNameToUsers([response.data], (users) => {
        //     utils.addProfileQuestionsNameToUser(users[0])
        //         .then(newUser => {
        //             response.data = newUser;
        //             dispatch(success(response.data));
        //         })
        //         .catch(error => {
        //             console.log('addProfileQuestionsNameToUser error=', error);
        //             if (error && error.response && error.response.status && error.response.data.message) {
        //                 dispatch(failure(error.response.status, error.response.data.message))
        //             } else {
        //                 dispatch(failure(-1));
        //             }
        //         });
        // });
        var countries = response.data;
        dispatch(success(response.data));
      })
      .catch((error) => {
        console.log("viewProfile error=", error);
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request() {
    return {
      type: countryConstants.GET_COUNTRY_REQUEST,
    };
  }

  function success(responseData) {
    console.log("in success", responseData);
    return {
      type: countryConstants.GET_COUNTRY_SUCCESS,
      responseData: responseData,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: countryConstants.GET_COUNTRY_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}

function getStates(countryId) {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getStates(countryId)
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request() {
    return {
      type: countryConstants.GET_STATE_REQUEST,
    };
  }

  function success(responseData) {
    return {
      type: countryConstants.GET_STATE_SUCCESS,
      responseData: responseData,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: countryConstants.GET_STATE_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}

function getCities(countryId, stateId) {
  console.log("in city action", countryId, stateId);
  return (dispatch) => {
    dispatch(request());
    apiService
      .getCities(countryId, stateId)
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request() {
    return {
      type: countryConstants.GET_CITY_REQUEST,
    };
  }

  function success(responseData) {
    return {
      type: countryConstants.GET_CITY_SUCCESS,
      responseData: responseData,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: countryConstants.GET_CITY_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}
