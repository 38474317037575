export const resetPasswordConstants = {
    OTP_REQUEST: 'OTP_REQUEST',
    OTP_SUCCESS: 'OTP_SUCCESS',
    OTP_FAILURE: 'OTP_FAILURE',
    CLEAR:'CLEAR',

    OTP_VERIFICATION_REQUEST: 'OTP_VERIFICATION_REQUEST',
    OTP_VERIFICATION_SUCCESS: 'OTP_VERIFICATION_SUCCESS',
    OTP_VERIFICATION_FAILURE: 'OTP_VERIFICATION_FAILURE',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
};
