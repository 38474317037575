import {userConstants} from "../constants/userConstants";

const initialState = {
    getTrendingUserState: {
        gettingUsers: false,
        invalidData: false,
        internalError: false,
        users: [],
        errorMessage: ''
    },
    getUserState: {
        gettingUsers: false,
        invalidData: false,
        internalError: false,
        users: [],
        nextPage: 0,
        errorMessage: ''
    },
    responseUserState: {
        respondingUser: false,
        invalidData: false,
        internalError: false,
        responseData: null,
        errorMessage: ''
    },
    getSearchUserState: {
        searchingUser: false,
        invalidData: false,
        internalError: false,
        users: [],
        nextPage: 0,
        errorMessage: ''
    },
    reportUserState: {
        reportingUser: false,
        invalidData: false,
        internalError: false,
        responseData: null,
        errorMessage: ''

    },
    nearMeUsersState: {
        gettingUsers: false,
        invalidData: false,
        internalError: false,
        nearMeUsers: [],
        errorMessage: ''

    },
    nearMeUsersSettingsState: {
        savingSettings: false,
        invalidData: false,
        internalError: false,
        responseData: null,
        errorMessage: ''

    },
    fetchNearMeUsersSettingsState: {
        fetchingSettings: false,
        nearMeSettingsInvalidData: false,
        nearMeSettingsInternalError: false,
        responseData: null,
        nearMeErrorMessage: ''

    }
};

const getTrendingUserReducer = (state = initialState.getTrendingUserState, action) => {
    switch (action.type) {
        case userConstants.GET_TRENDING_USERS_REQUEST:
            return {...state, gettingUsers: true};
        case userConstants.GET_TRENDING_USERS_SUCCESS:
            console.log('getTrendingUserReducer users=', action.responseData.data);
            return {...state, users: action.responseData.data};
        case userConstants.GET_TRENDING_USERS_FAILURE:
            switch (action.errorCode) {
                case 400:
                    return {
                        ...initialState.getTrendingUserState,
                        invalidData: true,
                        errorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.getTrendingUserState,
                        internalError: true
                    }
            }
        default:
            return state;
    }
};

const getUserReducer = (state = initialState.getUserState, action) => {
    switch (action.type) {
        case userConstants.GET_USERS_REQUEST:
            return {...state, gettingUsers: true};
        case userConstants.GET_USERS_SUCCESS:
            const {users} = state;
            const newUsers = action.responseData.data;
            const {pagination} = action.responseData.meta;
            for (let i = 0; i < newUsers.length; i++) {
                users.push(newUsers[i]);
            }
            return {
                ...state,
                users,
                totalUsers: users.length,
                gettingUsers: false,
                page: pagination.current_page,
                lastPage: pagination.current_page === pagination.total_pages
            };
        case userConstants.GET_USERS_FAILURE:
            switch (action.errorCode) {
                case 400:
                    return {
                        ...initialState.getUserState,
                        invalidData: true,
                        errorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.getUserState,
                        internalError: true
                    }
            }
        default:
            return state;
    }
};

const respondUserReducer = (state = initialState.responseUserState, action) => {
    switch (action.type) {
        case userConstants.RESPOND_USER_REQUEST:
            return {...initialState.responseUserState, respondingUser: true};
        case userConstants.RESPOND_USER_SUCCESS:
            return {...initialState.responseUserState, responseData: action.responseData};
        case userConstants.RESPOND_USER_FAILURE:
            switch (action.errorCode) {
                case 400:
                    return {
                        ...initialState.responseUserState,
                        invalidData: true,
                        errorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.responseUserState,
                        internalError: true
                    }
            }
        default:
            return state;
    }
};

const getSearchUserReducer = (state = initialState.getSearchUserState, action) => {
    console.log('getSearchUserReducer action=', action.searchParams);
    console.log('getSearchUserReducer users=', JSON.stringify(state.users.length));
    switch (action.type) {
        case userConstants.SEARCH_USER_REQUEST:
            if (action.reset) {
                return {
                    ...initialState.getSearchUserState,
                    searchParams: action.searchParams,
                    searchType: action.searchType,
                    searchingUser: true
                };
            }
            return {...state, searchParams: action.searchParams, searchType: action.searchType, searchingUser: true};
        case userConstants.SEARCH_USER_SUCCESS:
            let {users} = state;
            if (action.reset) {
                users = []
            }
            const newUsers = action.responseData.data;
            const {pagination} = action.responseData.meta;
            for (let i = 0; i < newUsers.length; i++) {
                users.push(newUsers[i]);
            }
            console.log('getSearchUserReducer users=', users);
            return {
                ...state,
                users,
                totalUsers: users.length,
                searchingUser: false,
                page: pagination.current_page,
                lastPage: pagination.current_page === pagination.total_pages
            };
        case userConstants.SEARCH_USER_FAILURE:
            switch (action.errorCode) {
                case 400:
                    return {
                        ...initialState.getSearchUserState,
                        invalidData: true,
                        errorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.getSearchUserState,
                        internalError: true
                    }
            }
        default:
            return initialState.getSearchUserState;
    }
};

const reportUserReducer = (state = initialState.reportUserState, action) => {
    switch (action.type) {
        case userConstants.REPORT_USER_REQUEST:
            return {...state, reportingUser: true};
        case userConstants.REPORT_USER_SUCCESS:
            return {
                responseData: action.responseData
            };
        case userConstants.REPORT_USER_FAILURE:
            switch (action.errorCode) {
                case 400:
                    return {
                        ...initialState.reportUserState,
                        invalidData: true,
                        errorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.reportUserState,
                        internalError: true
                    }
            }
        default:
            return state;
    }
};
const nearMeUsersReducer = (state = initialState.nearMeUsersState, action) => {
    switch (action.type) {
        case userConstants.NEAR_ME_USER_REQUEST:
            if (action.reset) {
                return {...initialState.nearMeUsersState, gettingUsers: true};
            }
            return {...state, gettingUsers: true};

        case userConstants.NEAR_ME_USER_SUCCESS:
            let {nearMeUsers} = state;
            if (action.reset) {
                nearMeUsers = [];
            }
            const newNearMeUsers = action.responseData.data;
            const {pagination} = action.responseData.meta;
            for (let i = 0; i < newNearMeUsers.length; i++) {
                nearMeUsers.push(newNearMeUsers[i]);
            }

            console.log('getSearchUserReducer users=', JSON.stringify(state.nearMeUsers.length));
            console.log('getSearchUserReducer users=', nearMeUsers);
            return {
                ...state,
                nearMeUsers,
                totalFriends: nearMeUsers.length,
                gettingUsers: false,
                page: pagination.current_page,
                totalPage: pagination.total_pages,
                lastPage: pagination.current_page === pagination.total_pages
            };

        case userConstants.NEAR_ME_USER_FAILURE:
            switch (action.errorCode) {
                case 400:
                    return {
                        ...initialState.nearMeUsersState,
                        invalidData: true,
                        errorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.nearMeUsersState,
                        internalError: true
                    }
            }
        default:
            return initialState.nearMeUsersState;
    }
};
const nearMeUsersSettingsReducer = (state = initialState.nearMeUsersSettingsState, action) => {
    switch (action.type) {
        case userConstants.NEAR_ME_USER_SETTINGS_REQUEST:
            return {...state, savingSettings: true};
        case userConstants.NEAR_ME_USER_SETTINGS_SUCCESS:
            return {...initialState.nearMeUsersSettingsState, responseData: action.responseData};
        case userConstants.NEAR_ME_USER_SETTINGS_FAILURE:
            switch (action.errorCode) {
                case 400:
                    return {
                        ...initialState.nearMeUsersSettingsState,
                        invalidData: true,
                        errorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.nearMeUsersSettingsState,
                        internalError: true
                    }
            }
        default:
            return state;
    }
};
const fetchNearMeUsersSettingsReducer = (state = initialState.fetchNearMeUsersSettingsState, action) => {
    switch (action.type) {
        case userConstants.GET_NEAR_ME_USER_SETTINGS_REQUEST:
            return {...state, fetchingSettings: true};
        case userConstants.GET_NEAR_ME_USER_SETTINGS_SUCCESS:
            return {
                ...initialState.fetchNearMeUsersSettingsState,
                fetchingSettings: false, responseData: action.responseData
            };
        case userConstants.GET_NEAR_ME_USER_SETTINGS_FAILURE:
            switch (action.errorCode) {
                case 400:
                    return {
                        ...initialState.fetchNearMeUsersSettingsState,
                        nearMeSettingsInvalidData: true,
                        nearMeErrorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.fetchNearMeUsersSettingsState,
                        nearMeSettingsInternalError: true
                    }
            }
        default:
            return state;
    }
};
export {
    getTrendingUserReducer,
    getUserReducer,
    respondUserReducer,
    getSearchUserReducer,
    reportUserReducer,
    nearMeUsersReducer,
    nearMeUsersSettingsReducer,
    fetchNearMeUsersSettingsReducer
}
