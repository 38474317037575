import {apiService} from "../services/apiService";
import {messageConstants} from "../constants/messageConstants";
import {userConstants} from "../constants/userConstants";
import firebase from "firebase";
import 'firebase/database'
import {notificationConstants} from "../constants/notificationConstants";

export const messageActions = {
    getMessageUsers,
    getMessages,
    sendMessage,
    listenForNewMessages
};

function getMessageUsers(page = 1) {
    return dispatch => {
        dispatch(request(page === 1));
        apiService.getMessageUsers(page)
            .then(response => {
                console.log('getMessageUsers response=', response.data);
                dispatch(success(response.data, page === 1));
            })
            .catch(error => {
                    console.log('getMessageUsers error=', error);
                    if (error && error.response && error.response.status && error.response.data.message) {
                        dispatch(failure(error.response.status, error.response.data.message))
                    } else {
                        dispatch(failure(-1));
                    }
                }
            )
    };

    function request(reset) {
        return {
            type: messageConstants.GET_MESSAGE_USERS_REQUEST,
            reset
        };
    }

    function success(responseData, reset) {
        return {
            type: messageConstants.GET_MESSAGE_USERS_SUCCESS,
            responseData,
            reset
        }
    }

    function failure(errorCode, errorMessage) {
        return {
            type: messageConstants.GET_MESSAGE_USERS_FAILURE,
            errorCode: errorCode, errorMessage: errorMessage
        };
    }
}

function getMessages(userId, createdAt = '') {
    return dispatch => {
        dispatch(request(userId, !createdAt));
        apiService.getMessages(userId, createdAt)
            .then(response => {
                console.log('getMessages response=', response.data);
                dispatch(success(response.data, !createdAt));
            })
            .catch(error => {
                    console.log('getMessages error=', error);
                    if (error && error.response && error.response.status && error.response.data.message) {
                        dispatch(failure(error.response.status, error.response.data.message))
                    } else {
                        dispatch(failure(-1));
                    }
                }
            )
    };

    function request(userId, reset) {
        return {
            type: messageConstants.GET_MESSAGES_REQUEST,
            userId,
            reset
        };
    }

    function success(responseData, reset) {
        return {
            type: messageConstants.GET_MESSAGES_SUCCESS,
            responseData,
            reset
        }
    }

    function failure(errorCode, errorMessage) {
        return {
            type: messageConstants.GET_MESSAGES_FAILURE,
            errorCode: errorCode, errorMessage: errorMessage
        };
    }
}

function sendMessage(userId, message, deviceMessageId, resend = false) {
    return dispatch => {
        dispatch(request(userId, message, deviceMessageId));
        apiService.sendMessage(userId, message, deviceMessageId)
            .then(response => {
                console.log('sendMessage response=', response.data);
                dispatch(success(deviceMessageId));
            })
            .catch(error => {
                    console.log('sendMessage error=', error);
                    if (error && error.response && error.response.status && error.response.data.message) {
                        dispatch(failure(error.response.status, error.response.data.message))
                    } else {
                        dispatch(failure(-1, '', deviceMessageId));
                    }
                }
            )
    };

    function request(userId, message) {
        return {
            type: resend ? messageConstants.RESEND_MESSAGE_REQUEST : messageConstants.SEND_MESSAGE_REQUEST,
            userId,
            message,
            deviceMessageId
        }
    }

    function success(deviceMessageId) {
        return {
            type: messageConstants.SEND_MESSAGE_SUCCESS,
            deviceMessageId
        }
    }

    function failure(errorCode, errorMessage, deviceMessageId) {
        return {
            type: messageConstants.SEND_MESSAGE_FAILURE,
            errorCode: errorCode, errorMessage: errorMessage,
            deviceMessageId
        };
    }
}

function listenForNewMessages(timeout = 2000) {
    const id = localStorage.getItem(userConstants.USER_ID);

    console.log('listenForNewMessages.id=', id);
    return dispatch => {
        const ref = firebase.database().ref();

        const userRef = ref.child('chat_message').child(id);

        console.log('listenForNewMessages.userRef=', userRef);

        userRef.on("child_added", snapshot => {
            onChildAddedOrChanged(snapshot);
        });

        userRef.on("child_changed", snapshot => {
            onChildAddedOrChanged(snapshot);
        });

        function onChildAddedOrChanged(snapshot) {
            if (snapshot && snapshot.val()) {
                const {notification_id, sender_id, name, device_message_id, message, updated_at} = snapshot.val();
                console.log('listenForNewMessages on message received=', {
                    notification_id,
                    sender_id,
                    message,
                    device_message_id,
                    updated_at
                });
                dispatch(onNewMessageReceived(sender_id, message, device_message_id, updated_at));
                dispatch(onNewNotification('message', notification_id, {
                    sender_id,
                    name,
                    message,
                    device_message_id,
                    updated_at
                }));
                setTimeout(() => {
                    console.log('onNewNotification timeout');
                    dispatch(onTimeout());
                }, timeout);

            }
        }
    };

    function onNewMessageReceived(sender_id, message, device_message_id, updated_at) {
        return {
            type: messageConstants.NEW_MESSAGE_RECEIVED,
            userId: sender_id + '',
            chatText: message,
            deviceMessageId: device_message_id,
            updatedAt: updated_at
        }
    }

    function onNewNotification(notification_type, notification_id, payload) {
        return {
            type: notificationConstants.NOTIFICATION_TYPE_MESSAGE,
            notificationType: notification_type,
            notificationId: notification_id,
            payload: {
                userId: payload.sender_id + '',
                name: payload.name,
                chatText: payload.message,
                deviceMessageId: payload.device_message_id,
                updatedAt: payload.updated_at
            }
        }
    }


    function onTimeout() {
        return {
            type: notificationConstants.ON_TIMEOUT,
        }
    }
}
