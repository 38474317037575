import React, { Component } from "react";
import { friendActions } from "../../actions/friendActions";
import { connect } from "react-redux";
import { history } from "../app/App";
import { utils } from "../../helper/utils";
import Loader from "../Loader/Loader";
import { default as LOCALE } from "../../localization";
import { userConstants } from "../../constants/userConstants";
import PendingFriendRequests from "./pendingFriendRequests";
import SentFriendRequests from "./sentFriendRequests";
import Modal from "react-modal";
import { friendsConstants } from "../../constants/friendsConstants";

class ViewFriends extends Component {
  constructor(props) {
    super(props);
    this.state = {
      all: true,
      sent: false,
      pending: false,
      showModal: false,
      friend: null,
    };
  }
  componentDidMount() {
    this.props.getFriendsList();
  }

  handleImageClick = (userProfileId) => {
    history.push(`/users/${userProfileId}`);
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
  };
  cancelFriendRequest = (id) => {
    this.setState({
      showModal: true,
      friend: id,
    });
  };

  confirmCancel = (id) => {
    console.log("cancel req", id);
    this.props.respondFriend(
      this.state.friend,
      friendsConstants.FRIEND_UNFRIEND
    );
    this.setState({
      showModal: false,
    });
  };

  selectPending = () => {
    this.setState({ all: false, sent: false, pending: true });
  };
  selectSent = () => {
    this.setState({ all: false, sent: true, pending: false });
  };
  selectAll = () => {
    this.setState({ all: true, sent: false, pending: false });
  };

  paginateIfNecessary = (e) => {
    const { gettingFriends, lastPage, page } = this.props.getFriendsListReducer;
    console.log("paginateIfNecessary", {
      scrollHeight: e.target.scrollHeight,
      scrollTop: e.target.scrollTop,
      clientHeight: e.target.clientHeight,
    });
    const bottom =
      Math.round(e.target.scrollHeight) - Math.round(e.target.scrollTop) ===
      Math.round(e.target.clientHeight);

    console.log("paginateIfNecessary bottom", bottom);

    if (bottom) {
      if (lastPage || gettingFriends) {
        gettingFriends
          ? console.log("getting search users already")
          : console.log("last page reached");
      } else {
        console.log("getting more users", page);
        this.props.getFriendsList(page + 1);
      }
    }
  };

  render() {
    LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
    const {
      gettingFriends,
      friends,
      invalidData,
      internalError,
      errorMessage,
    } = this.props.getFriendsListReducer;
    console.log("searchResults render users=", friends);
    console.log("friend tabs", this.state);
    if (friends.length > 0) {
      return (
        <div className="home-page-wrapper search-result">
          <div className="home-page">
            <div className="top-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>{LOCALE.all_friends}</h3>
            </div>

            <div className="friend-tab">
              <span
                className={this.state.all ? "selected-friend-tab" : ""}
                onClick={this.selectAll}
              >
                <p>My friends</p>
              </span>
              <span
                className={this.state.pending ? "selected-friend-tab" : ""}
                onClick={this.selectPending}
              >
                <p>Friend Request</p>
              </span>
              <span
                className={this.state.sent ? "selected-friend-tab" : ""}
                onClick={this.selectSent}
              >
                <p>Sent Request</p>
              </span>
            </div>

            {this.state.all && (
              <div className="scrollx" onScroll={this.paginateIfNecessary}>
                {friends.map((friend, i) => (
                  <div
                    onClick={this.handleImageClick.bind(this, friend.user_id)}
                    key={i}
                  >
                    <div className="searched-image">
                      <div className="searched-img">
                        <img
                          key={i}
                          className="img-search"
                          src={friend.user_image_path.image_250}
                          alt="user"
                        />
                      </div>
                      <div className="searched-det">
                        <label>{`${friend.username}, ${friend.user_age}`}</label>
                        <br />
                        <label>{friend.user_country_name}, </label>
                        {friend.user_state_name !== "None" ? (
                          <label>{friend.user_state_name}, </label>
                        ) : (
                          ""
                        )}
                        <label>{friend.user_city_name}</label>
                      </div>
                      <div
                        // onClick={this.cancelFriendRequest(
                        //   sentFriendRequest.user_id
                        // )}
                        onClick={(e) =>
                          this.cancelFriendRequest(friend.friend_id)
                        }
                        className="respond-icons-cancel-sent"
                      >
                        <span class="material-symbols-outlined">close</span>
                      </div>

                      <div class="line"></div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <Modal
              isOpen={this.state.showModal}
              contentLabel="Minimal Modal Example"
              ariaHideApp={false}
              onClose={this.handleCloseModal}
            >
              <img
                className="cancel-icon"
                onClick={this.handleCloseModal}
                src={require("../../img/cancel.png")}
                alt="cancel-button"
              />
              <h3>Cancel Request</h3>

              <p>Are you sure you want to cancel request?</p>
              <button onClick={this.handleCloseModal}>{LOCALE.no}</button>
              <button onClick={this.confirmCancel}>{LOCALE.yes}</button>
            </Modal>
            {this.state.pending && <PendingFriendRequests />}
            {this.state.sent && <SentFriendRequests />}

            {gettingFriends && <Loader />}

            {/*<div>Back to Profile?*/}
            {/*    <button className="btn-clear top-space"*/}
            {/*            onClick={() => {*/}
            {/*                history.goBack();*/}
            {/*            }}><strong>Click here</strong>*/}
            {/*    </button>*/}
            {/*</div>*/}
          </div>
        </div>
      );
    }
    if (gettingFriends) {
      return (
        <div className="home-page-wrapper">
          <div className="home-page">
            {/* <div className="top-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>{LOCALE.all_friends}</h3>
            </div> */}
            <Loader />
          </div>
        </div>
      );
    } else {
      return (
        <div className="home-page-wrapper friends-list">
          <div className="home-page">
            <div className="top-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>{LOCALE.all_friends}</h3>
            </div>
            <div className="homepage-content">
              <h2>{LOCALE.no_friends_yet}</h2>
              {gettingFriends && <Loader />}
              {internalError && <div>{LOCALE.internal_error}</div>}
              {invalidData && <div>{errorMessage}</div>}
              {/* <button
                className="btn00"
                onClick={() => {
                  history.push("/search");
                }}
              >
                {" "}
                {LOCALE.searchFriends}
              </button> */}
              {/*
                            <div>Back to Profile?
                                <button className="btn-clear top-space"
                                        onClick={() => {
                                            history.goBack();
                                        }}><strong>Click here</strong>
                                </button>
                            </div>*/}
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapState = (state) => {
  return {
    getFriendsListReducer: state.getFriendsListReducer,
    respondFriendReducer: state.respondFriendReducer,
    checkFriendStatusReducer: state.checkFriendStatusReducer,
  };
};
const actionCreators = {
  getFriendsList: friendActions.getFriendsList,
  respondFriend: friendActions.respondFriend,
  checkFriendStatus: friendActions.checkFriendStatus,
};
export default connect(mapState, actionCreators)(ViewFriends);
