import React, { Component } from "react";
import { userActions } from "../../actions/userActions";
import { connect } from "react-redux";
import { utils } from "../../helper/utils";
import { history } from "../app/App";
import Loader from "../Loader/Loader";
import { default as LOCALE } from "../../localization";
import { userConstants } from "../../constants/userConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRemove,
  faHeart,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import TinderCard from "react-tinder-card";

class UserStack extends Component {
  constructor(props) {
    super(props);
    // this.swipeRef = React.createRef();
    this.swipeRefs = this.props?.getUserReducer?.users?.reduce((acc, user) => {
      acc[user.id] = React.createRef();
      return acc;
    }, {});

    this.state = {
      index: 0,
      imageLoading: false,
      paginationOffset: 0.75,
      currentid: 0,
      topCardIndex: null,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.props.nextUsers();
    this.setState({ topCardIndex: 0 });
  }

  handleImageClick = (userProfileId) => {
    console.log("handleClick userProfileId=", userProfileId);
    history.push(`/users/${userProfileId}`);
  };

  paginateIfNecessary = () => {
    const { totalUsers, gettingUsers, lastPage, page } =
      this.props.getUserReducer;
    const { index, paginationOffset } = this.state;

    if (index >= Math.trunc(totalUsers * paginationOffset)) {
      if (lastPage || gettingUsers) {
        gettingUsers
          ? console.log("getting users already")
          : console.log("last page reached");
      } else {
        this.props.nextUsers(page + 1);
      }
    }
  };

  onYesClicked = (id) => {
    console.log("top card index", this.state.topCardIndex);
    console.log("id passed", id);
    console.log("yes");
    this.paginateIfNecessary();

    const { users } = this.props.getUserReducer;
    const { index } = this.state;

    const status = "YES";

    this.props.respondUser(users[index].id, status);
    // this.props.respondUser(id, status);

    this.setState((prevState) => ({
      imageLoading: true,
      index: prevState.index + 1,
    }));
  };

  onNoClicked = (id) => {
    console.log("top card index", this.state.topCardIndex);

    // console.log("id passed", users[index].id);

    console.log("no");
    this.paginateIfNecessary();

    const { users } = this.props.getUserReducer;
    const { index } = this.state;

    const status = "NO";

    this.props.respondUser(users[index].id, status);
    // this.props.respondUser(id, status);

    this.setState((prevState) => ({
      imageLoading: true,
      index: prevState.index + 1,
    }));
  };

  // onNoClicked = () => {
  //   console.log("no");

  //   this.paginateIfNecessary();

  //   this.setState((prevState) => ({
  //     imageLoading: true,
  //     index: prevState.index + 1,
  //   }));
  // };

  onImageLoaded = () => {
    this.setState({
      imageLoading: false,
      lastDirection: "",
    });
  };

  setTopCardIndex = () => {
    const { users } = this.props.getUserReducer;
    console.log("swipe ref", this.swipeRef);
    const topCard = this.swipeRef.current;
    const topCardIndex = users.findIndex(
      (user) => user.id === topCard.props.children.key
    );
    this.setState({ topCardIndex });
    console.log("changed top card", this.state.topCardIndex);
  };

  render() {
    LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
    const { gettingUsers, users } = this.props.getUserReducer;
    const { imageLoading, index, topCardIndex } = this.state;

    console.log("this this this", this);
    const swiped = (direction, id) => {
      console.log("direction: " + direction);
      console.log("swiped id: " + id);

      if (direction == "left" || direction == "down") {
        this.onNoClicked(id);
      }

      if (direction == "right" || direction == "down") {
        this.onYesClicked(id);
      }

      this.setState({
        lastDirection: direction,
      });
    };

    const outOfFrame = (name) => {
      console.log(name + " left the screen!");
    };

    if (users.length > 0 && index < users.length) {
      const {
        id,
        // about_me,
        user_age,
        // city_id,
        // country_id,
        username,
        name,
        // gender,
        image_path,
        // make_private,
        // my_interest,
        // seeking,
        // state_id,
        // status_id,
        user_profile_id,
        user_country,
        user_state,
        user_city,
      } = users[index];

      const { image_orig, image_250, image_350 } = image_path;

      return (
        <div>
          <div className="user-stack">
            <div className="home-image">
              <img
                hidden={imageLoading}
                className="Image-Placeholder"
                src={image_orig}
                onLoad={this.onImageLoaded}
                onClick={this.handleImageClick.bind(this, id)}
                alt="clock-sample"
              />
              <label className={imageLoading ? "loading-text" : "div-hide"}>
                <Loader />
              </label>

              <div classname="imagetext">
                <div className="stack-imagetext">
                  <label className="user-name">{`${name}, ${user_age}`}</label>
                  {/* <div className="country-name">
                    <label>{user_country}, </label>
                    {user_state !== "None" ? <label>{user_state}, </label> : ""}
                    <label>{user_city}</label>
                  </div> */}
                  <div className="card-location-container location-card">
                    <FontAwesomeIcon
                      className="card-location-icon"
                      icon={faLocationDot}
                    />
                    <p>{`${
                      user_city + ", " + user_state + ", " + user_country
                    }`}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <label className="user-name">{`${username}, ${utils.getAge(
            age
              )}`}</label> */}

            {/* <label className="user-name">{`${name}, ${user_age}`}</label>
          <div className="country-name">
            <label>{user_country}, </label>
            {user_state !== "None" ? <label>{user_state}, </label> : ""}
            <label>{user_city}</label>
          </div> */}

            {/* <button className="btn-home-clear" onClick={this.onNoClicked}>
            No
          </button> */}

            <div className="swipe-buttons">
              <FontAwesomeIcon
                onClick={this.onNoClicked}
                className="btn-home-clear"
                icon={faRemove}
              />
              {/* <button className="btn-home" onClick={this.onYesClicked}>
            Yes
          </button> */}
              <FontAwesomeIcon
                onClick={this.onYesClicked}
                className="btn-home"
                icon={faHeart}
              />
            </div>
          </div>
          {/* <div>
            <link
              href="https://fonts.googleapis.com/css?family=Damion&display=swap"
              rel="stylesheet"
            />
            <link
              href="https://fonts.googleapis.com/css?family=Alatsi&display=swap"
              rel="stylesheet"
            />
            <h3>Do you want to meet me?</h3>
            <div className="tinder-cards">
              <div className="card-container">
                {console.log("total users", users)}
                {users.map((user, index) => (
                  <TinderCard
                    ref={this.swipeRefs[user.id]}
                    className="swipe"
                    key={user.name}
                    onSwipe={(dir) => swiped(dir, user.id, index)}
                    onCardLeftScreen={() => outOfFrame(user.name, index)}
                  >
                    <div
                      style={{
                        backgroundImage:
                          "url(" + user.image_path.image_orig + ")",
                      }}
                      className="card"
                    >
                      <h3>{user.name}</h3>
                      <div className="card-location-container">
                        <FontAwesomeIcon
                          className="card-location-icon"
                          icon={faLocationDot}
                        />
                        <p>{`${
                          user.user_city +
                          ", " +
                          user.user_state +
                          ", " +
                          user.user_country
                        }`}</p>
                      </div>
                    </div>
                  </TinderCard>
                ))}
                <FontAwesomeIcon
                  onClick={() => {
                    this.onNoClicked();
                    this.setTopCardIndex();
                  }}
                  className="btn-home-clear"
                  icon={faRemove}
                />

                <FontAwesomeIcon
                  onClick={() => {
                    this.onYesClicked();
                    this.setTopCardIndex();
                  }}
                  className="btn-home"
                  icon={faHeart}
                />
              </div>
            </div>
            {this.state.lastDirection ? (
              <h2 className="infoText">
                You swiped {this.state.lastDirection}
              </h2>
            ) : (
              <h2 className="infoText" />
            )}
          </div> */}
        </div>
      );
    } else if (gettingUsers) {
      return (
        <div className="user-stack getting-users">
          <Loader />
        </div>
      );
    } else {
      return (
        <div className="no-more-user">
          <img
            className="no-more-image"
            src={require("../../img/no-more-image.png")}
            alt="logo"
          />
          {LOCALE.no_more_members_availabe}
        </div>
      );
    }
  }
}

const mapState = (state) => {
  return {
    getUserReducer: state.getUserReducer,
    respondUserReducer: state.respondUserReducer,
  };
};

const actionCreators = {
  nextUsers: userActions.nextUsers,
  respondUser: userActions.respondUser,
};

export default connect(mapState, actionCreators)(UserStack);
