import { apiService } from "../services/apiService";
import { utils } from "../helper/utils";
import { viewsConstants } from "../constants/viewsConstants";
import firebase from "firebase";
import "firebase/database";

export const viewActions = {
  getSentViewsList,
  getReceivedViewsList,
};

function getSentViewsList() {
  return (dispatch) => {
    dispatch(request());
    apiService
      .iViewed()
      .then((response) => {
        console.log("getviews response=");
        // utils.addCountryStateCityNameToUsers(response.data.data, (users) => {
        //     response.data.data = users;
        //     dispatch(success(response.data, page === 1));
        // });
        var users = response.data.data;
        dispatch(success(response.data.data));
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request(reset) {
    return {
      type: viewsConstants.FETCH_SENT_VIEWS_LISTS_REQUEST,
      reset,
    };
  }

  function success(responseData, reset) {
    return {
      type: viewsConstants.FETCH_SENT_VIEWS_LISTS_SUCCESS,
      responseData: responseData,
      reset,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: viewsConstants.FETCH_SENT_VIEWS_LISTS_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}

function getReceivedViewsList() {
  return (dispatch) => {
    dispatch(request());
    apiService
      .viewedMe()
      .then((response) => {
        console.log("getviews response=");
        // utils.addCountryStateCityNameToUsers(response.data.data, (users) => {
        //     response.data.data = users;
        //     dispatch(success(response.data, page === 1));
        // });
        var users = response.data.data;
        dispatch(success(response.data.data));
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request(reset) {
    return {
      type: viewsConstants.FETCH_RECEIVED_VIEWS_LISTS_REQUEST,
      reset,
    };
  }

  function success(responseData, reset) {
    return {
      type: viewsConstants.FETCH_RECEIVED_VIEWS_LISTS_SUCCESS,
      responseData: responseData,
      reset,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: viewsConstants.FETCH_RECEIVED_VIEWS_LISTS_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}
