import { matchesConstants } from "../constants/matchesConstants";

const initialState = {
  getMatchesList: {
    gettingMatches: false,
    invalidData: false,
    internalError: false,
    matches: [],
    nextPage: 0,
    errorMessage: "",
  },
};

const getMatchesListReducer = (state = initialState.getMatchesList, action) => {
  switch (action.type) {
    case matchesConstants.FETCH_MATCHES_LISTS_REQUEST:
      if (action.reset) {
        return { ...initialState.getMatchesList, gettingMatches: true };
      }
      return { ...state, gettingMatches: true };
    case matchesConstants.FETCH_MATCHES_LISTS_SUCCESS:
      let { matches } = state;
      if (action.reset) {
        matches = [];
      }

      const newMatches = action.responseData;
      console.log("error69", action);
      //   const { pagination } = action.responseData.meta;
      for (let i = 0; i < newMatches.length; i++) {
        matches.push(newMatches[i]);
      }
      console.log("getFriendsLists matches=", matches);
      return {
        ...state,
        matches,
        totalMatches: matches.length,
        gettingMatches: false,
        // page: pagination.current_page,
        // lastPage: pagination.current_page === pagination.total_pages,
      };
    case matchesConstants.FETCH_MATCHES_LISTS_FAILURE:
      switch (action.errorCode) {
        case 400:
          return {
            ...initialState.getMatchesList,
            invalidData: true,
            errorMessage: action.errorMessage,
          };
        default:
          return {
            ...initialState.getMatchesList,
            internalError: true,
          };
      }
    default:
      return state;
  }
};

export { getMatchesListReducer };
