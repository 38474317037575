import React, { Component } from "react";
import { matchActions } from "../../actions/matchActions.js";
import { connect } from "react-redux";
import { history } from "../app/App";
import { utils } from "../../helper/utils";
import Loader from "../Loader/Loader";
import { default as LOCALE } from "../../localization";
import { userConstants } from "../../constants/userConstants";
import Tab from "../tab/tab";
import SideDrawer from "../SideDrawer/SideDrawer";

class ViewMatches extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sideDrawerOpen: false,
    };
  }

  componentDidMount() {
    this.props.getMatchesList();
  }

  handleImageClick = (userProfileId) => {
    history.push(`/users/${userProfileId}`);
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  dismissSideDrawer = () => {
    this.setState({ sideDrawerOpen: false });
  };

  paginateIfNecessary = (e) => {
    const { gettingMatches, lastPage, page } = this.props.getMatchesListReducer;
    console.log("paginateIfNecessary", {
      scrollHeight: e.target.scrollHeight,
      scrollTop: e.target.scrollTop,
      clientHeight: e.target.clientHeight,
    });
    const bottom =
      Math.round(e.target.scrollHeight) - Math.round(e.target.scrollTop) ===
      Math.round(e.target.clientHeight);

    console.log("paginateIfNecessary bottom", bottom);

    if (bottom) {
      if (lastPage || gettingMatches) {
        gettingMatches
          ? console.log("getting search users already")
          : console.log("last page reached");
      } else {
        console.log("getting more users", page);
        this.props.getMatchesList(page + 1);
      }
    }
  };

  render() {
    LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
    const {
      gettingMatches,
      matches,
      invalidData,
      internalError,
      errorMessage,
    } = this.props.getMatchesListReducer;
    const { sideDrawerOpen } = this.state;

    console.log("searchResults render matches=", matches);
    if (matches?.length > 0) {
      return (
        <div className="home-page-wrapper search-result">
          <div className="home-page">
            <div className="top-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>{LOCALE.all_matches}</h3>
            </div>

            <div className="scrollx" onScroll={this.paginateIfNecessary}>
              {matches?.map((match, i) => (
                <div
                  onClick={this.handleImageClick.bind(this, match.id)}
                  key={i}
                >
                  <div className="searched-image">
                    <div className="searched-img">
                      <img
                        key={i}
                        className="img-search"
                        src={match.image_path.image_250}
                        alt="user"
                      />
                    </div>
                    <div className="searched-det">
                      <label>{`${match.name}, ${match.user_age}`}</label>
                      <br />
                      <label>{match.user_country}, </label>
                      {match.user_state_name !== "None" ? (
                        <label>{match.user_state}, </label>
                      ) : (
                        ""
                      )}
                      <label>{match.user_city}</label>
                    </div>
                    <div class="line"></div>
                  </div>
                </div>
              ))}
            </div>
            {gettingMatches && <Loader />}

            <div>
              Back to Profile?
              <button
                className="btn-clear top-space"
                onClick={() => {
                  history.goBack();
                }}
              >
                <strong>Click here</strong>
              </button>
            </div>

            <div className="homepage-content2">
              <SideDrawer
                show={sideDrawerOpen}
                onOutsideClick={this.dismissSideDrawer}
              />
            </div>

            <div className="tab-footer">
              <Tab drawerClickHandler={this.drawerToggleClickHandler} />
            </div>
          </div>
        </div>
      );
    }
    if (gettingMatches) {
      return (
        <div className="home-page-wrapper">
          <div className="home-page">
            <div className="top-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>{LOCALE.all_matches}</h3>
            </div>
            <Loader />
          </div>
        </div>
      );
    } else {
      return (
        <div className="home-page-wrapper friends-list">
          <div className="home-page">
            <div className="top-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>{LOCALE.all_matches}</h3>
            </div>
            <div className="homepage-content">
              <h2>{LOCALE.no_matches_yet}</h2>
              {gettingMatches && <Loader />}
              {internalError && <div>{LOCALE.internal_error}</div>}
              {invalidData && <div>{errorMessage}</div>}
              {/* <button
                className="btn00"
                onClick={() => {
                  history.push("/search");
                }}
              >
                {" "}
                {LOCALE.searchMatches}
              </button> */}
              {/*
                            <div>Back to Profile?
                                <button className="btn-clear top-space"
                                        onClick={() => {
                                            history.goBack();
                                        }}><strong>Click here</strong>
                                </button>
                            </div>*/}
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapState = (state) => {
  return {
    getMatchesListReducer: state.getMatchesListReducer,
  };
};
const actionCreators = {
  getMatchesList: matchActions.getMatchesList,
};
export default connect(mapState, actionCreators)(ViewMatches);
