import React, {Component} from 'react';
import {connect} from "react-redux";
import {resetPasswordAction} from "../../actions/resetPasswordAction";
import {history} from "../app/App";
import {default as LOCALE} from "../../localization";
import {userConstants} from "../../constants/userConstants";

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: '',
            fieldEmpty: false,
            passwordValid: true
        }
    }

    onInput = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value})
    };

    isValidPassword = (str) => {
        return str.match(/[a-z]/) && str.match(/[A-Z]/) && str.length >= 8;
    };

    resetPassword = () => {
        const {newPassword} = this.state;

        if (newPassword.length === 0) {
            this.setState({fieldEmpty: true});
            return;
        }

        this.setState({fieldEmpty: false});

        if (!this.isValidPassword(newPassword)) {
            this.setState({passwordValid: false});
            return;
        }

        this.setState({passwordValid: true});

        const userId = this.props.otpVerifyReducer.responseData.userId;

        this.props.resetPassword(newPassword, userId);
    };

    render() {
        LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        const {resettingPassword, invalidData, internalError, errorMessage} = this.props.resetPasswordReducer;
        const {fieldEmpty, newPassword, passwordValid} = this.state;

        return (
            <div className="login-page">
                <div className="login-form reset-password">
                    <h3>{LOCALE.enter_new_password}</h3>
                    {fieldEmpty && <div className="user-pw">{LOCALE.enter_password_to}</div>}
                    {invalidData && <div className="user-pw">{errorMessage}</div>}
                    {internalError && <div className="user-pw">{LOCALE.internal_error}</div>}
                    {!passwordValid &&
                    <div className="user-pw">{LOCALE.password_validation}</div>}
                    <input
                        className={fieldEmpty || invalidData || !passwordValid ? "input-field input-field-error" : "input-field"}
                        disabled={resettingPassword}
                        type='text'
                        placeholder={LOCALE.new_password}
                        name='newPassword'
                        value={newPassword}
                        onChange={this.onInput}/>

                    <button className={resettingPassword ? "btn1 login-btn-disable" : "btn1"}
                            disabled={resettingPassword}
                            onClick={this.resetPassword}>{resettingPassword ? "" : LOCALE.reset_password}
                    </button>
                    {resettingPassword && <div className="lds-dual-ring"/>}

                    <p>{LOCALE.back_to_login}
                        <button className="btn-clear top-space1"
                                onClick={() => {
                                    history.goBack();
                                    history.goBack();
                                    history.goBack();
                                }}><strong>{LOCALE.click_here}</strong>
                        </button>
                    </p>
                </div>
            </div>
        );
    }
}


const actionCreators = {
    resetPassword: resetPasswordAction.resetPassword
};


const mapState = (state) => {
    return {
        otpVerifyReducer: state.otpVerifyReducer,
        resetPasswordReducer: state.resetPasswordReducer,
    };
};

export default connect(mapState, actionCreators)(ResetPassword);


