import React, { Component } from "react";
import { history } from "../app/App";
import { accountSettingsAction } from "../../actions/accountSettingsAction";
import { connect } from "react-redux";
import { default as LOCALE } from "../../localization";
import { userConstants } from "../../constants/userConstants";

class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otpCode: "",
      seconds: 90,
      fieldEmpty: false,
    };
  }

  componentDidMount() {
    this.timeoutCounter = setInterval(() => {
      const { verifyingOTP } = this.props.requestVerifyEmailReducer;

      if (verifyingOTP) {
        return;
      }

      const { seconds } = this.state;

      if (seconds <= 0) {
        clearInterval(this.timeoutCounter);
        history.goBack();
      }

      this.setState(({ seconds }) => ({
        seconds: seconds - 1,
      }));
    }, 1000);
  }

  getRemainingTime = () => {
    const minutes = Math.trunc(this.state.seconds / 60);
    let seconds = this.state.seconds % 60;

    seconds = seconds < 10 ? `0${seconds}` : seconds;

    console.log("min=", minutes);
    console.log("sec=", seconds);
    return `${minutes}:${seconds}`;
  };

  componentWillUnmount() {
    clearInterval(this.timeoutCounter);
  }

  onInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  verifyOtpEmail = (e) => {
    e.preventDefault();

    console.log("verifyOTP");

    const { otpCode } = this.state;
    if (otpCode.length === 0) {
      this.setState({ fieldEmpty: true });
      return;
    }

    this.setState({ fieldEmpty: false });
    const { insertId } = this.props.location.state;

    console.log("to verify", this.props);

    this.props.verifyOtpEmail(insertId, otpCode);
  };

  render() {
    LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
    const { verifyingOTP, invalidData, internalError, errorMessage } =
      this.props.requestVerifyEmailReducer;
    const { fieldEmpty, otpCode } = this.state;

    return (
      <div className="login-page">
        <div className="login-form">
          {/*            <div className="code-sent-msg">
                        {prevResponse}
                    </div>*/}

          <button
            onClick={() => {
              history.goBack();
            }}
          >
            {LOCALE.edit_email}
          </button>

          <h4>
            {LOCALE.time_remaining} {this.getRemainingTime()}
          </h4>

          <div>
            <div className="top-space forgot-msg">
              {fieldEmpty && <div>{LOCALE.enter_otp_to_continue}</div>}
              {invalidData && <div>{errorMessage}</div>}
              {internalError && <div>{LOCALE.internal_error}</div>}
            </div>

            <input
              disabled={verifyingOTP}
              className={
                fieldEmpty || internalError || invalidData
                  ? "input-field input-field-error"
                  : "input-field"
              }
              type="number"
              name="otpCode"
              value={otpCode}
              placeholder={LOCALE.enter_otp}
              onChange={this.onInput}
            />
            <br />

            <button
              className="btn1"
              disabled={verifyingOTP}
              onClick={this.verifyOtpEmail}
            >
              {" "}
              {verifyingOTP ? "" : LOCALE.verify}{" "}
            </button>

            {verifyingOTP && <div className="lds-dual-ring" />}

            <br />

            <div>
              {LOCALE.back_to_profile}
              <button
                className="btn-clear top-space"
                onClick={() => {
                  history.goBack();
                  history.goBack();
                }}
              >
                <strong>{LOCALE.click_here}</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const actionCreators = {
  verifyOtpEmail: accountSettingsAction.verifyEmail,
};

const mapState = (state) => {
  return {
    requestVerifyEmailReducer: state.requestVerifyEmailReducer,
  };
};

export default connect(mapState, actionCreators)(VerifyEmail);
