import {resetPasswordConstants} from "../constants/resetPasswordConstants";

const initialState = {
    otpRequest: {
        sendingOTP: false,
        invalidData: false,
        internalError: false,
        responseData: {},
        errorMessage: ''
    },
    otpVerify: {
        verifyingOTP: false,
        invalidData: false,
        internalError: false,
        responseData: {},
        errorMessage: ''
    },
    resetPassword: {
        resettingPassword: false,
        invalidData: false,
        internalError: false,
        responseData: {},
        errorMessage: ''
    },
};

const otpRequestReducer = (state = initialState.otpRequest, action) => {
    console.log("the reducer action is ",action);
    switch (action.type) {
        case resetPasswordConstants.OTP_REQUEST:
            return {...initialState.otpRequest, sendingOTP: true};
        case resetPasswordConstants.OTP_SUCCESS:
            return {...initialState.otpRequest, responseData: action.responseData};
        case resetPasswordConstants.OTP_FAILURE:
            switch (action.errorCode) {
                case 400:
                    return {
                        ...initialState.otpRequest,
                        invalidData: true,
                        errorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.otpRequest,
                        internalError: true
                    }
            }
        case resetPasswordConstants.CLEAR:
        default:
            return initialState.otpRequest;
    }
};

const otpVerifyReducer = (state = initialState.otpVerify, action) => {
    switch (action.type) {
        case resetPasswordConstants.OTP_VERIFICATION_REQUEST:
            return {...initialState.otpVerify, verifyingOTP: true};
        case resetPasswordConstants.OTP_VERIFICATION_SUCCESS:
            return {...initialState.otpVerify, responseData: action.responseData};
        case resetPasswordConstants.OTP_VERIFICATION_FAILURE:
            switch (action.errorCode) {
                case 400:
                    return {
                        ...initialState.otpVerify,
                        invalidData: true,
                        errorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.otpVerify,
                        internalError: true
                    }
            }
        default:
            return state;
    }
};

const resetPasswordReducer = (state = initialState.resetPassword, action) => {
    switch (action.type) {
        case resetPasswordConstants.RESET_PASSWORD_REQUEST:
            return {...initialState.resetPassword, resettingPassword: true};
        case resetPasswordConstants.RESET_PASSWORD_SUCCESS:
            return {...initialState.resetPassword};
        case resetPasswordConstants.RESET_PASSWORD_FAILURE:
            switch (action.errorCode) {
                case 400:
                    return {
                        ...initialState.resetPassword,
                        invalidData: true,
                        errorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.resetPassword,
                        internalError: true
                    }
            }
        default:
            return state;
    }
};

export {otpRequestReducer, otpVerifyReducer, resetPasswordReducer}
