import {apiService} from "../services/apiService";
import {history} from "../components/app/App";
import {resetPasswordConstants} from "../constants/resetPasswordConstants";

export const resetPasswordAction = {requestOtp, verifyOtp, resetPassword};

function requestOtp(username) {
    return dispatch => {
        if (!username){
            dispatch(clearState());
        }
        else {
            dispatch(request());
            apiService.sendOTP(username)
                .then(response => {
                    console.log('requestOtp response=', response);

                    const insertId = response.data.insert_id;
                    const responseMessage = response.data.message;

                    dispatch(success(insertId, responseMessage));

                    history.push('/otpVerify')

                })
                .catch(error => {
                        console.log('requestOtp error=', error);
                        if (error && error.response && error.response.status && error.response.data.message) {
                            dispatch(failure(error.response.status, error.response.data.message))
                        } else {
                            dispatch(failure(-1));
                        }
                    }
                )
        }
    };

    function clearState() {
        return {
            type: resetPasswordConstants.CLEAR
        };
    }
    function request() {
        return {
            type: resetPasswordConstants.OTP_REQUEST
        };
    }

    function success(insertId, message) {
        return {
            type: resetPasswordConstants.OTP_SUCCESS,
            responseData: {insertId, message}
        }
    }

    function failure(errorCode, errorMessage) {
        return {
            type: resetPasswordConstants.OTP_FAILURE,
            errorCode: errorCode,
            errorMessage: errorMessage
        };
    }
}

function verifyOtp(otpCode, insertId) {
    console.log('verifyOtp');
    return dispatch => {

        dispatch(request());
        apiService.verifyOTP(otpCode, insertId)
            .then(response => {
                const responseMessage = response.data.username;
                const userId = response.data.user_id;
                dispatch(success(userId, responseMessage));
                history.push('/resetPassword')
            })
            .catch(error => {
                if (error && error.response && error.response.status && error.response.data.message) {
                    dispatch(failure(error.response.status, error.response.data.message))
                } else {
                    dispatch(failure(-1));
                }
            });
    };

    function request() {
        return {
            type: resetPasswordConstants.OTP_VERIFICATION_REQUEST
        }
    }

    function success(userId, responseMessage) {
        return {
            type: resetPasswordConstants.OTP_VERIFICATION_SUCCESS,
            responseData: {userId, responseMessage},
        }
    }

    function failure(errorCode, errorMessage) {
        return {
            type: resetPasswordConstants.OTP_VERIFICATION_FAILURE,
            errorCode: errorCode,
            errorMessage: errorMessage
        };
    }
}

function resetPassword(newPassword, userId) {
    return dispatch => {
        dispatch(request());
        apiService.resetPassword(newPassword, userId)
            .then((response) => {
                dispatch(success());
                history.goBack();
                history.goBack();
                history.goBack()
            })
            .catch(error => {
                if (error && error.response && error.response.status && error.response.data.message) {
                    dispatch(failure(error.response.status, error.response.data.message))
                } else {
                    dispatch(failure(-1));
                }
            })
    };

    function request() {
        return {
            type: resetPasswordConstants.RESET_PASSWORD_REQUEST
        }
    }

    function success() {
        return {
            type: resetPasswordConstants.RESET_PASSWORD_SUCCESS
        }
    }

    function failure(errorCode, errorMessage) {
        return {
            type: resetPasswordConstants.RESET_PASSWORD_FAILURE,
            errorCode: errorCode,
            errorMessage: errorMessage
        };
    }

}

