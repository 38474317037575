import React, {Component} from 'react';
import {default as S} from "../localization";
import {userConstants} from "../constants/userConstants";

/*
let strings = new LocalizedStrings({
    en:{
        how:"How do you want your egg today?",
        boiledEgg:"Boiled egg",
        softBoiledEgg:"Soft-boiled egg",
        choice:"How to choose the egg"
    },
    it: {
        how:"Come vuoi il tuo uovo oggi?",
        boiledEgg:"Uovo sodo",
        softBoiledEgg:"Uovo alla coque",
        choice:"Come scegliere l'uovo"
    }
});*/
class Localization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: localStorage.getItem(userConstants.LANGUAGE_DEFAULT)
        };

        this.handleLanguageChange = this.handleLanguageChange.bind(this);
    }

    handleLanguageChange(e) {
        e.preventDefault();
        let lang = e.target.value;
        this.setState(prevState => ({
            language: lang
        }));
        localStorage.setItem(userConstants.LANGUAGE_DEFAULT,lang)
    }

    render() {
        S.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        return (
            <div>
                Change Language: <select onChange={this.handleLanguageChange}>
                <option value="en">En- English</option>
                <option value="es">Es-Espanol</option>
            </select>
                <br/><br/>
                {S.how}
                <br/><br/>
                {S.softBoiledEgg}
                {/* <br /><br />
                {strings.choice}
                <br /><br />
                {strings.how}*/}
            </div>
        )
    }
}

export default Localization;
