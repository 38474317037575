import {helperConstants} from "../constants/helperConstants";

const initialState = {
    country: {
        countryList: [],
        stateList: [],
        cityList: []
    }
};

const countryReducer = (state = initialState.country, action) => {
    switch (action.type) {
        case helperConstants.GET_COUNTRY_LIST:
            return {countryList: action.countryList, stateList: action.stateList, cityList: action.cityList};
        case helperConstants.GET_STATE_LIST:
            return {...state, stateList: action.stateList, cityList: action.cityList};
        case helperConstants.GET_CITY_LIST:
            return {...state, cityList: action.cityList};
        default:
            return state;
    }
};

export {countryReducer};
