import { apiService } from "../services/apiService";
import { photoConstants } from "../constants/photoConstants";
import { history } from "../components/app/App";
import { utils } from "../helper/utils";

export const photoActions = {
  updatePhoto,
  getPhoto,
};

function updatePhoto(image) {
  return (dispatch) => {
    dispatch(request());
    apiService
      .updatePhoto(image)
      .then((response) => {
        console.log("updatePhoto responseData=", response);

        const responseData = response.data;

        dispatch(success(responseData));

        history.goBack();
      })
      .catch((error) => {
        console.log("updatePhoto error=", error);
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request() {
    return {
      type: photoConstants.UPDATE_PHOTO_REQUEST,
    };
  }

  function success(responseData) {
    return {
      type: photoConstants.UPDATE_PHOTO_SUCCESS,
      responseData: responseData,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: photoConstants.UPDATE_PHOTO_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}

function getPhoto() {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getPhoto()
      .then((response) => {
        console.log("viewPhoto responseData=", response);

        var newUser = response.data;
        dispatch(success(response.data));
      })
      .catch((error) => {
        console.log("viewProfile error=", error);
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request() {
    return {
      type: photoConstants.VIEW_PHOTO_REQUEST,
    };
  }

  function success(responseData) {
    return {
      type: photoConstants.VIEW_PHOTO_SUCCESS,
      responseData: responseData,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: photoConstants.VIEW_PHOTO_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}
