export const registrationConstants = {
    OTP_REQUEST: 'OTP_REQUEST',
    OTP_SUCCESS: 'OTP_SUCCESS',
    OTP_FAILURE: 'OTP_FAILURE',

    OTP_VERIFICATION_REQUEST: 'OTP_VERIFICATION_REQUEST',
    OTP_VERIFICATION_SUCCESS: 'OTP_VERIFICATION_SUCCESS',
    OTP_VERIFICATION_FAILURE: 'OTP_VERIFICATION_FAILURE',

    REGISTRATION_REQUEST: 'REGISTRATION_REQUEST',
    REGISTRATION_SUCCESS: 'REGISTRATION_SUCCESS',
    REGISTRATION_FAILURE: 'REGISTRATION_FAILURE',

};
