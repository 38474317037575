import React, { Component } from "react";
import "../../css/style.css";
import { messageActions } from "../../actions/messageActions";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import Notification from "../notification/Notification";
import { history } from "../app/App";
import { notificationActions } from "../../actions/notificationActions";
import ChatBubble from "./chatBubble";
import Loader from "../Loader/Loader";
import { default as LOCALE } from "../../localization";
import { userConstants } from "../../constants/userConstants";

class ChatFeed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: this.props.location.state,
    };

    this.scrollDivRef = React.createRef();
    this.messageInputField = React.createRef();

    this.shouldScrollToEnd = true;
  }

  componentDidMount() {
    const { id } = this.state.user;

    console.log("onload id", this.state.user);

    this.loadMessages(id);
  }

  loadMessages = (user_id) => {
    this.props.excludeNotification("message", user_id);
    this.props.getMessages(user_id);
  };

  paginateIfNecessary = (e) => {
    const { id } = this.state.user;
    const { gettingMessages, lastPage, lastCreatedAt, lastChatId } =
      this.props.messageReducer;

    console.log("paginateIfNecessary", {
      scrollHeight: e.target.scrollHeight,
      scrollTop: e.target.scrollTop,
      clientHeight: e.target.clientHeight,
    });

    const top = Math.trunc(e.target.scrollTop) === 0;
    const bottom =
      Math.round(e.target.scrollHeight) - Math.round(e.target.scrollTop) ===
      Math.round(e.target.clientHeight);

    console.log("paginateIfNecessary top", top);

    if (top) {
      if (this.state.bottom) {
        this.setState({ bottom: false });
      }
      if (lastPage || gettingMessages) {
        gettingMessages
          ? console.log("getting message already")
          : console.log("last page reached");
      } else {
        console.log("getting more message lastCreatedAt=", lastCreatedAt);
        this.shouldScrollToEnd = false;
        this.lastChatId = lastChatId;
        this.props.getMessages(id, lastCreatedAt);
      }
    } else if (bottom) {
      this.setState({ bottom: true });
      this.shouldScrollToEnd = true;
    } else if (this.state.bottom) {
      this.setState({ bottom: false });
    }
  };

  sendMessage = () => {
    const { id } = this.state.user;

    const message = this.messageInputField.current.value;

    if (!message) {
      return;
    }

    this.shouldScrollToEnd = true;

    const randomString = Math.random().toString(36).substring(7);
    console.log("send msg", id, message);

    this.props.sendMessage(id, message, randomString);

    this.messageInputField.current.value = "";
  };

  componentDidUpdate() {
    console.log("componentDidUpdate()");

    if (this.shouldScrollToEnd) {
      this.scrollToEnd();
    } else if (this.lastChatId) {
      const node = ReactDOM.findDOMNode(this[this.lastChatId]);
      if (node) {
        node.scrollIntoView();
      }
    }
  }

  scrollToEnd = () => {
    this.shouldScrollToEnd = true;
    const objDiv = this.scrollDivRef.current;
    objDiv.scrollTop = objDiv.scrollHeight;
  };

  resendMessage = (message, deviceMessageId) => {
    const { user_id } = this.state.user;

    console.log("msg id", user_id);

    this.props.sendMessage(user_id, message, deviceMessageId, true);
  };

  onNotificationClick = (type, payload) => {
    const { userId } = payload;
    console.log("onNotificationClick", payload);

    const { messageUsers } = this.props.getMessageUsersReducer;

    for (let i = 0; i < messageUsers.length; i++) {
      const user = messageUsers[i];

      if (user.user_id === userId) {
        this.setState({ user });
        this.loadMessages(userId);

        return;
      }
    }
  };

  openProfile = (userProfileId) => {
    console.log("openProfile userProfileId=", userProfileId);
    history.push(`/users/${userProfileId}`);
  };

  render() {
    LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
    const { user, otherUserMessage, bottom } = this.state;
    const {
      gettingMessages,
      messages,
      invalidData,
      internalError,
      errorMessage,
    } = this.props.messageReducer;

    const { id, user_profile_id, image_path, username } = user;

    console.log("test messages=", user);

    return (
      <div className="home-page-wrapper chat-feed-page">
        <div className="home-page">
          <div
            className="top-bar"
            onClick={() => {
              this.openProfile(id);
            }}
          >
            <img
              onClick={(e) => {
                e.stopPropagation();
                history.goBack();
              }}
              src={require("../../img/back.png")}
              alt="back-button"
            />
            <img
              className="top-bar-img"
              src={image_path?.image_350}
              alt="user profile"
            />
            <h3>{username}</h3>
          </div>

          <Notification
            onClick={this.onNotificationClick}
            type="message"
            showLastOnly={true}
          />

          <div
            className="scrollx chatfeed-loading"
            ref={this.scrollDivRef}
            onScroll={this.paginateIfNecessary}
          >
            {gettingMessages && (
              <div>
                <Loader />
              </div>
            )}
            {invalidData && <div>{LOCALE.invalid_data}</div>}
            {internalError && <div>{errorMessage}</div>}
            {messages && (
              <div className="chat-feed">
                {messages.map((message, index) => (
                  <ChatBubble
                    key={index}
                    ref={(ref) => (this[message.device_message_id] = ref)}
                    prevMessage={messages[index - 1]}
                    message={message}
                    nextMessage={messages[index + 1]}
                    resendMessage={this.resendMessage}
                    other={message.receiver_id === id}
                  />
                ))}
              </div>
            )}
          </div>

          {!bottom && (
            <button className="btn01 go-down" onClick={this.scrollToEnd}>
              <img src={require("../../img/down-arrow.png")} alt="Down" />
            </button>
          )}

          <div className="tab-footer">
            <input
              ref={this.messageInputField}
              className="input-field"
              type="text"
              name="message"
              onChange={this.onMessageInput}
            />
            <button className="btn00" onClick={this.sendMessage}>
              {LOCALE.send}
            </button>
            {otherUserMessage && <div>{otherUserMessage}</div>}
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state) => {
  return {
    messageReducer: state.messageReducer,
    getMessageUsersReducer: state.getMessageUsersReducer,
  };
};

const actionCreators = {
  getMessages: messageActions.getMessages,
  sendMessage: messageActions.sendMessage,
  excludeNotification: notificationActions.excludeNotification,
};

export default connect(mapState, actionCreators)(ChatFeed);
