import React, { Component } from "react";
import { default as LOCALE } from "../../../localization";
import { userConstants } from "../../../constants/userConstants";

class SingleChoiceQuestion extends Component {
  constructor(props) {
    super(props);
    console.log("inside singles", this.props?.question);

    //initialization
    const {
      id: profile_setup_id,
      name,
      options,
      selected_question_option_ids,
      required,
    } = this.props.question;

    console.log("this.props.question;", this.props.question);

    if (required === "Y" && !selected_question_option_ids) {
      this.props.onInputError(profile_setup_id, true);
      this.state = { inputError: true };
    } else {
      this.state = { inputError: false };
    }

    const answers = [];

    answers.push("-");

    let selected_question_option_index = 0;
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      answers.push(option.option_value);
      if (option.selected === true) {
        selected_question_option_index = i + 1;
      }
      console.log("single answer", answers);
    }

    this.question = {
      required,
      selected_question_option_index,
      name,
      answers,
    };
    console.log("questions single", this.question);
  }

  onAnswerChanged = (selectedIndex) => {
    const {
      id: profile_setup_id,
      field_type_id,
      options,
      required,
    } = this.props.question;

    console.log("inside singles q", this.props.question, selectedIndex);

    //user cannot select nothing for required field
    if (required === "Y" && selectedIndex === "0") {
      this.setState({ inputError: true });
      this.props.onInputError(profile_setup_id, true);
      return;
    }

    this.setState({ inputError: false });
    this.props.onInputError(profile_setup_id, false);

    //since this is optional field user can remove it
    if (required !== "Y" && selectedIndex === "0") {
      this.props.onAnswerChanged(profile_setup_id, {
        field_type_id,
        profile_setup_id,
        question_option_id: 0,
      });
      return;
    }

    const { question_option_id, option_value } = options[selectedIndex - 1];
    this.props.onAnswerChanged(profile_setup_id, {
      field_type_id,
      profile_setup_id,
      question_option_id,
      option_value,
    });
  };

  render() {
    LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
    const { name, answers, selected_question_option_index, required } =
      this.question;
    const { inputError } = this.state;

    return (
      <div className="edit-questions">
        {inputError && (
          <div>
            {name} {LOCALE.is_required}
          </div>
        )}
        <label>
          {name}
          <span>{required === "Y" ? "*" : ""}</span>
        </label>
        <div id="basic-select">
          <select
            className={
              inputError ? "input-field input-field-error" : "input-field"
            }
            defaultValue={selected_question_option_index}
            onChange={(e) => {
              this.onAnswerChanged(e.target.value);
            }}
          >
            {answers.map((answer, i) => (
              <option key={i} value={i}>
                {answer}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}

export default SingleChoiceQuestion;
