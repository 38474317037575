export const genderConstants = {
    GENDER_MALE: 'Male',
    GENDER_FEMALE: 'Female',
    GENDER_COUPLES: 'Couples',

    GENDER_TYPE_MALE: 'M',
    GENDER_TYPE_FEMALE: 'F',
    GENDER_TYPE_COUPLES: 'C',

};
