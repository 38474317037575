import * as firebase from "firebase/app";
import "firebase/messaging";

const config = {
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSENGING_SENDER_ID,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET
};

const initializedFirebaseApp = firebase.initializeApp(config);

let messaging = {};

if (firebase.messaging.isSupported()) {
    messaging = initializedFirebaseApp.messaging();

    messaging.usePublicVapidKey(
        process.env.REACT_APP_FIREBASE_WEB_PUSH_CERTIFICATE
    );
}

export {messaging};

