import {apiService} from "../services/apiService";
import {accessFeatureConstants} from "../constants/accessFeatureConstants";

export const featureAccessAction = {
    checkFeatureAccess
};

function checkFeatureAccess(feature) {
    return dispatch => {
        dispatch(request());
        apiService.checkFeatureAccess(feature)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                    console.log('getAccessFeature error=', error);
                    if (error && error.response && error.response.status && error.response.data.message) {
                        dispatch(failure(error.response.status, error.response.data.message))
                    } else {
                        dispatch(failure(-1));
                    }
                }
            )
    };

    function request() {
        return {
            type: accessFeatureConstants.CHECK_ACCESS_FEATURE_REQUEST
        };
    }

    function success(responseData) {
        return {
            type: accessFeatureConstants.CHECK_ACCESS_FEATURE_SUCCESS,
            responseData: responseData
        }
    }

    function failure(errorCode, errorMessage) {
        return {
            type: accessFeatureConstants.CHECK_ACCESS_FEATURE_FAILURE,
            errorCode: errorCode, errorMessage: errorMessage
        };
    }

}
