import React, {Component} from 'react';
import {connect} from "react-redux";
import {userActions} from "../../actions/userActions";
import {utils} from "../../helper/utils";
import {history} from "../app/App";
import '../../css/style.css'
import {default as LOCALE} from "../../localization";
import {userConstants} from "../../constants/userConstants";


class ReportUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reportMessage: '',
            emptyMessage: false,


        }
    }

    onInput = (e) => {
        const {name, value} = e.target;
        this.setState({
            [name]: value
        })
    };

    reportUser = () => {
        const {initialData} = this.props.location.state;
        const {reportMessage} = this.state;

        if (reportMessage.length <= 0) {
            this.setState({emptyMessage: true});
            return
        }
        console.log("the report ", this.state.emptyMessage)

        this.setState({emptyMessage: false});

        this.props.reportUser(reportMessage, initialData.id);


    };

    render() {
        LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        const {initialData} = this.props.location.state;
        const {
            image,
            display_name,
            age,
            countryName,
            stateName,
            cityName,
        } = initialData;
        const {
            reportingUser,
            errorMessage,
            invalidData,
            internalError,
        } = this.props.reportUserReducer;

        const {reportMessage, emptyMessage} = this.state;

        return (
        <div className='home-page-wrapper'>
                <div className='home-page report-user-page'>
                    <div className="top-bar">
                        <img onClick={() => {
                            history.goBack();
                        }} src={require('../../img/back.png')} alt="back-button"/>
                        <h3>{LOCALE.report_user}</h3>
                    </div>
                    <div className="homepage-content">
                        <div className='report-page-image'>
                            <img className='Image-Placeholder2'
                                 src={image.image_orig}
                                 alt="clock-sample"/>
                        </div>
                        <div className="profile-view-name-wrapper">
                            <label className="profile-view-name">{display_name}, {utils.getAge(age)}</label>
                            <div className="profile-view-country">
                            <label>{countryName}, </label>
                            {stateName !== 'None' ? <label>{stateName}, </label> : ''}
                            <label>{cityName}</label>
                            </div>
                        </div>
                        <div className="report-reaseon">
                            <div>
                                {emptyMessage && <div>{LOCALE.enter_reason_for_reporting}</div>}
                                {invalidData && <div>{errorMessage}</div>}
                                {internalError && <div>{LOCALE.internal_error}</div>}
                                <label>{LOCALE.please_write_us_reason} </label><br/>
                                <textarea
                                    disabled={reportingUser}
                                    name="reportMessage" value={reportMessage} onChange={this.onInput}/>
                            </div>

                            <button disabled={reportingUser}
                                    onClick={this.reportUser}>{LOCALE.report}
                            </button>
                            <button disabled={reportingUser}
                                    onClick={history.goBack}>{LOCALE.cancel}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const actionCreators = {
    reportUser: userActions.reportUser
};

const mapState = (state) => {
    return {
        reportUserReducer: state.reportUserReducer,
        getProfileReducer: state.getProfileReducer

    }
};

export default connect(mapState, actionCreators)(ReportUser);
