import { apiService } from "../services/apiService";
import { history } from "../components/app/App";
import { registrationConstants } from "../constants/registrationConstants";
import { userConstants } from "../constants/userConstants";
import { resetPasswordConstants } from "../constants/resetPasswordConstants";

export const registrationAction = {
  requestOtpRegistration,
  verifyOtpRegistration,
  userRegistration,
};

function requestOtpRegistration(userEmail) {
  return (dispatch) => {
    if (!userEmail) {
      dispatch(clearState());
    } else {
      dispatch(request());
      apiService
        .sendOTPRegistration(userEmail)
        .then((response) => {
          console.log(
            "requestOtpRegistration response=",
            response.data.data.email
          );

          const insertId = response.data.data.email;
          const responseMessage = response.data.message;

          console.log("emails", insertId);

          dispatch(success(insertId, responseMessage));

          history.push("/otpVerifyRegistration");
        })
        .catch((error) => {
          console.log("requestOtpRegistration error=", error);
          if (
            error &&
            error.response &&
            error.response.status &&
            error.response.data.message
          ) {
            dispatch(
              failure(error.response.status, error.response.data.message)
            );
          } else {
            dispatch(failure(-1));
          }
        });
    }
  };

  function clearState() {
    return {
      type: resetPasswordConstants.CLEAR,
    };
  }
  function request() {
    return {
      type: registrationConstants.OTP_REQUEST,
    };
  }

  function success(insertId, message) {
    // console.log(this.props);
    return {
      type: registrationConstants.OTP_SUCCESS,
      responseData: { insertId, message },
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: registrationConstants.OTP_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}

function verifyOtpRegistration(otpCode, insertId) {
  return (dispatch) => {
    dispatch(request());
    apiService
      .verifyOTPRegistration(otpCode, insertId)
      .then((response) => {
        console.log("verifyOtpRegistration response=", response.data);
        const userEmail = response.data.user_email;
        const userId = response.data.id;
        dispatch(success(userId, userEmail));
        history.push("/registration");
      })
      .catch((error) => {
        console.log("verifyOtpRegistration error=", error);
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request() {
    return {
      type: registrationConstants.OTP_VERIFICATION_REQUEST,
    };
  }

  function success(userId, userEmail) {
    return {
      type: registrationConstants.OTP_VERIFICATION_SUCCESS,
      responseData: { userId, userEmail },
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: registrationConstants.OTP_VERIFICATION_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}

function userRegistration(params) {
  return (dispatch) => {
    dispatch(request());
    apiService
      .userRegistration(params)
      .then((response) => {
        apiService
          .login(params.username, params.password)
          .then((response) => {
            dispatch(success(response.data));

            const accessToken = response.data.access_token;
            console.log("login after registration response=", response.data);
            localStorage.setItem(userConstants.ACCESS_TOKEN, accessToken);
            history.goBack();
            history.goBack();
            history.goBack();
          })
          .catch((error) => {
            dispatch(success(response.data));

            console.log("login after registration error=", error);
            history.goBack();
            history.goBack();
            history.goBack();
          });
      })
      .catch((error) => {
        console.log("userRegistration error=", error);
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request() {
    return {
      type: registrationConstants.REGISTRATION_REQUEST,
    };
  }

  function success() {
    return {
      type: registrationConstants.REGISTRATION_SUCCESS,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: registrationConstants.REGISTRATION_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}
