import {messageConstants} from "../constants/messageConstants";
import {utils} from "../helper/utils";

const initialState = {
    getMessageUsersState: {
        gettingMessageUsers: false,
        invalidData: false,
        internalError: false,
        messageUsers: [],
        errorMessage: ''
    },
    messagesState: {
        gettingMessages: false,
        invalidData: false,
        internalError: false,
        messages: null,
        errorMessage: '',
        otherUserId: '',
        active: false
    }
};

const getMessageUsersReducer = (state = initialState.getMessageUsersState, action) => {
    switch (action.type) {
        case messageConstants.GET_MESSAGE_USERS_REQUEST:
            if (action.reset) {
                return {...initialState.getMessageUsersState, active: true, gettingMessageUsers: true};
            }
            return {...state, gettingMessageUsers: true};
        case messageConstants.GET_MESSAGE_USERS_SUCCESS:
            let {messageUsers} = state;
            if (action.reset) {
                messageUsers = [];
            }

            const newMessageUsers = action.responseData.data;
            const {pagination} = action.responseData.meta;
            for (let i = 0; i < newMessageUsers.length; i++) {
                messageUsers.push(newMessageUsers[i]);
            }
            console.log('getMessageUsersReducer messageUsers=', messageUsers);
            return {
                ...state,
                messageUsers,
                totalMessageUsers: messageUsers.length,
                gettingMessageUsers: false,
                page: pagination.current_page,
                lastPage: pagination.current_page === pagination.total_pages
            };
        case messageConstants.GET_MESSAGE_USERS_FAILURE:
            switch (action.errorCode) {
                case 400:
                    return {
                        ...initialState.getMessageUsersState,
                        invalidData: true,
                        errorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.getMessageUsersState,
                        internalError: true
                    }
            }
        default:
            return state;
    }
};

const messageReducer = (state = initialState.messagesState, action) => {
    switch (action.type) {
        case messageConstants.GET_MESSAGES_REQUEST:
            if (action.reset) {
                return {...initialState.messagesState, gettingMessages: true, otherUserId: action.userId, active: true};
            }
            return {...state, gettingMessages: true};
        case messageConstants.GET_MESSAGES_SUCCESS: {
            let {messages, otherUserId} = state;
            if (action.reset) {
                messages = [];
            }

            const responseMessages = action.responseData.data.reverse();

            const newMessages = [];

            responseMessages.forEach((responseMessage) => {
                if (otherUserId === responseMessage.receiver_id) {
                    newMessages.push({
                        ...responseMessage,
                        status: 'sent',
                        updated_at: utils.getLocalDateFromUTC(responseMessage.updated_at)
                    });
                } else {
                    newMessages.push({
                        ...responseMessage,
                        updated_at: utils.getLocalDateFromUTC(responseMessage.updated_at)
                    });
                }
            });

            const lastPage = newMessages && newMessages.length === 0;

            newMessages.push(...messages);

            console.log('messageReducer messages=', newMessages);
            return {
                ...state,
                messages: newMessages,
                totalMessages: newMessages.length,
                gettingMessages: false,
                lastPage,
                lastChatId: newMessages.length > 0 ? newMessages[0].device_message_id : '',
                lastCreatedAt: newMessages.length > 0 ? newMessages[0].created_at : ''
            };
        }
        case messageConstants.SEND_MESSAGE_REQUEST: {
            const message = {
                receiver_id: action.userId,
                chat_text: action.message,
                device_message_id: action.deviceMessageId,
                status: 'sending',
                updated_at: utils.getLocalDateFromUTC()
            };
            return {...state, messages: [...state.messages, message]};
        }
        case messageConstants.NEW_MESSAGE_RECEIVED: {
            console.log('action.sender_id=', action.userId);
            console.log('state=', state);
            if (state.active && state.otherUserId === action.userId) {
                const message = {
                    sender_id: action.userId,
                    chat_text: action.chatText,
                    device_message_id: action.deviceMessageId,
                    updated_at: utils.getLocalDateFromUTC(action.updatedAt)
                };
                return {...state, messages: [...state.messages, message]}
            }

            return state;
        }
        case messageConstants.RESEND_MESSAGE_REQUEST: {
            const {messages} = state;

            const newMessages = [];

            messages.forEach((message) => {
                if (message.device_message_id === action.deviceMessageId) {
                    newMessages.push({...message, status: 'sending'})
                } else {
                    newMessages.push(message)
                }
            });

            return {...state, messages: newMessages};
        }
        case messageConstants.SEND_MESSAGE_SUCCESS: {
            let {messages} = state;

            const newMessages = [];
            messages.forEach((message) => {
                if (message.device_message_id === action.deviceMessageId) {
                    newMessages.push({...message, status: 'sent'})
                } else {
                    newMessages.push(message)
                }
            });
            console.log('new messages=', newMessages);
            return {
                ...state,
                messages: newMessages
            };
        }
        case messageConstants.SEND_MESSAGE_FAILURE: {
            let {messages} = state;
            const newMessages = [];
            messages.forEach((message) => {
                if (message.device_message_id === action.deviceMessageId) {
                    newMessages.push({...message, status: 'failed'})
                } else {
                    newMessages.push(message)
                }
            });
            return {
                ...state,
                messages: newMessages
            };
        }
        case messageConstants.GET_MESSAGES_FAILURE:
            switch (action.errorCode) {
                case 400:
                    return {
                        ...initialState.messagesState,
                        invalidData: true,
                        errorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.messagesState,
                        internalError: true
                    }
            }
        default:
            return state;
    }
};

export {
    getMessageUsersReducer,
    messageReducer
}
