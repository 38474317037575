import React, {Component} from 'react';
import {accountSettingsAction} from "../../actions/accountSettingsAction";
import {connect} from "react-redux";
import {history} from "../app/App";
import {default as LOCALE} from "../../localization";
import {userConstants} from "../../constants/userConstants";

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: '',
            oldPassword: '',
            oldPasswordEmpty: false,
            newPasswordEmpty: false,
            passwordValid: true,


        }

    }

    onInput = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value})
    };

    isValidPassword = (str) => {
        console.log("the new password is = ", str);
        return str.match(/[a-z]/) && str.match(/[A-Z]/) && str.length >= 8;
    };

    changePassword = () => {
        const {oldPassword, newPassword} = this.state;

        if (oldPassword.length === 0) {
            this.setState({oldPasswordEmpty: true});
            return;
        }
        this.setState({oldPasswordEmpty: false});

        if (newPassword.length === 0) {
            this.setState({newPasswordEmpty: true});
            return;
        }

        this.setState({
            oldPasswordEmpty: false,
            newPasswordEmpty: false
        });

        if (!this.isValidPassword(newPassword)) {
            this.setState({passwordValid: false});
            return;
        }

        this.setState({passwordValid: true});

        this.props.changePassword(oldPassword, newPassword);
    };

    render() {
        LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        const {newPassword, oldPassword, oldPasswordEmpty, newPasswordEmpty, passwordValid} = this.state;
        const {updatingPassword, invalidData, internalError, errorMessage} = this.props.changePasswordReducer;
        return (
            <div className="login-page update-email">
                <div className="login-form">
                    <h6>{LOCALE.change_password}</h6>

                    <div className="forgot-msg">
                        {oldPasswordEmpty && <div>{LOCALE.enter_old_password}</div>}
                        {invalidData && <div>{errorMessage}</div>}
                        {internalError && <div>{LOCALE.internal_error}</div>}
                    </div>

                    <input
                        disabled={updatingPassword}
                        className={oldPasswordEmpty || internalError || invalidData ? "input-field input-field-error" : "input-field"}
                        type='password'
                        name="oldPassword"
                        value={oldPassword}
                        placeholder={LOCALE.enter_old_pass}
                        onChange={this.onInput}

                    />

                    <div className="forgot-msg">
                        {newPasswordEmpty && <div>{LOCALE.enter_new_password_to}</div>}

                        {!passwordValid &&
                        <div>{LOCALE.password_validation}</div>}
                    </div>
                    <input
                        disabled={updatingPassword}
                        className={newPasswordEmpty || internalError || invalidData ? "input-field input-field-error" : "input-field"}
                        type='password'
                        name="newPassword"
                        value={newPassword}
                        placeholder={LOCALE.new_password}
                        onChange={this.onInput}

                    />


                    <button className="btn1" disabled={updatingPassword}
                            onClick={this.changePassword}> {updatingPassword ? "" : LOCALE.change_password} </button>

                    {updatingPassword && <div className="lds-dual-ring"/>}

                    <br/>

                    <div>{LOCALE.back_to_settings}
                        <button className="btn-clear top-space"
                                onClick={() => {
                                    history.goBack();

                                }}><strong>{LOCALE.click_here}</strong>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

const actionCreators = {
    changePassword: accountSettingsAction.changePassword
};
const mapState = (state) => {
    return {
        changePasswordReducer: state.changePasswordReducer
    }
};
export default connect(mapState, actionCreators)(ChangePassword);
