import React, { Component } from "react";
import "../../css/style.css";

class Loader extends Component {
  render() {
    return (
      <div className="overlay">
        <div className="spinner center">
          {/* <span class="dating-loader"></span> */}
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
        </div>
      </div>
    );
  }
}

export default Loader;
