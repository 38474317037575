import {apiService} from "../services/apiService";
import {membershipPlansConstants} from "../constants/membershipPlansConstants";

export const membershipPlansActions = {getMembershipPlans, purchaseMembershipPlans, getMembershipPlanDetails};

function getMembershipPlans() {
    return dispatch => {
        dispatch(request());
        apiService.getMembershipPlansList()
            .then(response => {
                dispatch(success(response.data.data));
            })

            .catch(error => {
                    console.log('request membershiplists  error=', error);
                    if (error && error.response && error.response.status && error.response.data.message) {
                        dispatch(failure(error.response.status, error.response.data.message))
                    } else {
                        dispatch(failure(-1));
                    }
                }
            )
    };

    function request() {
        return {
            type: membershipPlansConstants.GET_MEMBERSHIP_PLANS_REQUEST
        };
    }

    function success(responseData) {
        return {
            type: membershipPlansConstants.GET_MEMBERSHIP_PLANS_SUCCESS,
            responseData: responseData
        }
    }

    function failure(errorCode, errorMessage) {
        return {
            type: membershipPlansConstants.GET_MEMBERSHIP_PLANS_FAILURE,
            errorCode: errorCode,
            errorMessage: errorMessage
        };
    }


}

function getMembershipPlanDetails(membershipId) {
    return dispatch => {
        dispatch(request());
        apiService.getMembershipPlanDetails(membershipId)
            .then(response => {
                dispatch(success(response.data));
            })

            .catch(error => {
                    console.log('request membershiplists  error=', error);
                    if (error && error.response && error.response.status && error.response.data.message) {
                        dispatch(failure(error.response.status, error.response.data.message))
                    } else {
                        dispatch(failure(-1));
                    }
                }
            )
    };

    function request() {
        return {
            type: membershipPlansConstants.GET_MEMBERSHIP_PLANS_DETAIL_REQUEST
        };
    }

    function success(responseData) {
        return {
            type: membershipPlansConstants.GET_MEMBERSHIP_PLANS_DETAIL_SUCCESS,
            responseData: responseData
        }
    }

    function failure(errorCode, errorMessage) {
        return {
            type: membershipPlansConstants.GET_MEMBERSHIP_PLANS_DETAIL_FAILURE,
            errorCode: errorCode,
            errorMessage: errorMessage
        };
    }


}

function purchaseMembershipPlans(token, product) {
    return dispatch => {
        dispatch(request());
        apiService.purchaseMembershipPlan(token, product)
            .then(response => {
                dispatch(success(response.data));
                console.log("I want", response.data)
            })

            .catch(error => {
                    console.log('request membershiplists  error=', error);
                    if (error && error.response && error.response.status && error.response.data.message) {
                        dispatch(failure(error.response.status, error.response.data.message))
                    } else {
                        dispatch(failure(-1));
                    }
                }
            )
    };

    function request() {
        return {
            type: membershipPlansConstants.PURCHASE_MEMBERSHIP_PLANS_REQUEST
        };
    }

    function success(responseData) {
        return {
            type: membershipPlansConstants.PURCHASE_MEMBERSHIP_PLANS_SUCCESS,
            responseData: responseData
        }
    }

    function failure(errorCode, errorMessage) {
        return {
            type: membershipPlansConstants.PURCHASE_MEMBERSHIP_PLANS_FAILURE,
            errorCode: errorCode,
            errorMessage: errorMessage
        };
    }


}

