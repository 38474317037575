import React, { Component } from "react";
import { viewActions } from "../../actions/viewActions";
import { connect } from "react-redux";
import { history } from "../app/App.js";
import { utils } from "../../helper/utils.js";
import Loader from "../Loader/Loader.js";
import { default as LOCALE } from "../../localization/index.js";
import { userConstants } from "../../constants/userConstants.js";
import { viewsConstants } from "../../constants/viewsConstants";

import Tab from "../tab/tab.js";
import SideDrawer from "../SideDrawer/SideDrawer.js";

class ViewViews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      received: true,
      sent: false,
    };
  }

  selectSent = () => {
    this.setState({ sent: true, received: false });
  };
  selectReceived = () => {
    this.setState({ sent: false, received: true });
  };

  componentDidMount() {
    this.props.getSentViewsList();
    this.props.getReceivedViewsList();
  }

  handleImageClick = (userProfileId) => {
    history.push(`/users/${userProfileId}`);
  };

  // drawerToggleClickHandler = () => {
  //   this.setState((prevState) => {
  //     return { sideDrawerOpen: !prevState.sideDrawerOpen };
  //   });
  // };

  // dismissSideDrawer = () => {
  //   this.setState({ sideDrawerOpen: false });
  // };

  render() {
    LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
    console.log("gift props", this.props);
    const {
      gettingViews,
      sentviews,
      receivedviews,
      invalidData,
      internalError,
      errorMessage,
    } = this.props.getSentViewsListReducer;

    console.log("searchResults render sentviews=", sentviews);
    console.log("searchResults render receivedviews=", receivedviews);

    if (sentviews?.length > 0 || receivedviews?.length > 0) {
      return (
        <div>
          <div className="home-page-wrapper search-result">
            <div className="home-page">
              <div className="top-bar">
                <img
                  onClick={() => {
                    history.goBack();
                  }}
                  src={require("../../img/back.png")}
                  alt="back-button"
                />
                <h3>{LOCALE.views}</h3>
              </div>

              <div className="friend-tab gift-tabs">
                <span
                  className={this.state.received ? "selected-friend-tab" : ""}
                  onClick={this.selectReceived}
                >
                  <p>{LOCALE.received_views}</p>
                </span>
                <span
                  className={this.state.sent ? "selected-friend-tab" : ""}
                  onClick={this.selectSent}
                >
                  <p>{LOCALE.sent_views}</p>
                </span>
              </div>

              {this.state.sent && (
                <div className="scrollx" onScroll={this.paginateIfNecessary}>
                  {sentviews?.map((view, i) => (
                    <div
                      onClick={this.handleImageClick.bind(this, view.id)}
                      key={i}
                    >
                      <div className="searched-image">
                        <div className="searched-img">
                          <img
                            key={i}
                            className="img-search"
                            src={view.image_path.image_250}
                            alt="user"
                          />
                        </div>
                        <div className="searched-det">
                          <label>{`${view.name}, ${view.user_age}`}</label>
                          <br />
                          <label>{view.user_country}, </label>
                          {view.user_state_name !== "None" ? (
                            <label>{view.user_state}, </label>
                          ) : (
                            ""
                          )}
                          <label>{view.user_city}</label>
                        </div>
                        <div class="line"></div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {this.state.received && (
                <div className="scrollx" onScroll={this.paginateIfNecessary}>
                  {receivedviews?.map((view, i) => (
                    <div
                      onClick={this.handleImageClick.bind(this, view.id)}
                      key={i}
                    >
                      <div className="searched-image">
                        <div className="searched-img">
                          <img
                            key={i}
                            className="img-search"
                            src={view.image_path.image_250}
                            alt="user"
                          />
                        </div>
                        <div className="searched-det">
                          <label>{`${view.name}, ${view.user_age}`}</label>
                          <br />
                          <label>{view.user_country}, </label>
                          {view.user_state_name !== "None" ? (
                            <label>{view.user_state}, </label>
                          ) : (
                            ""
                          )}
                          <label>{view.user_city}</label>
                        </div>
                        <div class="line"></div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {gettingViews && <Loader />}

              {/* <div>
                Back to Profile?
                <button
                  className="btn-clear top-space"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <strong>Click here</strong>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      );
    }
    if (gettingViews) {
      return (
        <div className="home-page-wrapper">
          <div className="home-page">
            <div className="top-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>{LOCALE.views}</h3>
            </div>
            <Loader />
          </div>
        </div>
      );
    } else {
      return (
        <div className="home-page-wrapper friends-list">
          <div className="home-page">
            <div className="top-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>{LOCALE.views}</h3>
            </div>
            <div className="homepage-content">
              <h2>{LOCALE.no_matches_yet}</h2>
              {gettingViews && <Loader />}
              {internalError && <div>{LOCALE.internal_error}</div>}
              {invalidData && <div>{errorMessage}</div>}
              {/* <button
                className="btn00"
                onClick={() => {
                  history.push("/search");
                }}
              >
                {" "}
                {LOCALE.searchViews}
              </button> */}
              {/*
                            <div>Back to Profile?
                                <button className="btn-clear top-space"
                                        onClick={() => {
                                            history.goBack();
                                        }}><strong>Click here</strong>
                                </button>
                            </div>*/}
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapState = (state) => {
  return {
    getSentViewsListReducer: state.getSentViewsListReducer,
    getReceivedViewsListReducer: state.getReceivedViewsListReducer,
  };
};
const actionCreators = {
  getReceivedViewsList: viewActions.getReceivedViewsList,
  getSentViewsList: viewActions.getSentViewsList,
};
export default connect(mapState, actionCreators)(ViewViews);
