import React, { Component } from "react";
import { connect } from "react-redux";
import { resetPasswordAction } from "../../actions/resetPasswordAction";
import { history } from "../app/App";
import { default as LOCALE } from "../../localization";
import { userConstants } from "../../constants/userConstants";

class OtpRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      fieldEmpty: false,
    };
  }

  componentDidMount() {
    console.log("the component did mount called");

    this.props.requestOtp();
  }

  onInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  requestOtp = (e) => {
    e.preventDefault();
    const { username } = this.state;
    if (username.length === 0) {
      this.setState({ fieldEmpty: true });
      return;
    }

    this.setState({
      fieldEmpty: false,
    });

    this.props.requestOtp(username);
  };

  render() {
    LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
    const { sendingOTP, invalidData, internalError, errorMessage } =
      this.props.otpRequestReducer;
    const { username, fieldEmpty } = this.state;
    console.log("the render is ", this.props.otpRequestReducer);

    return (
      <div className="login-page">
        <div className="login-form">
          <h3 className="login-page-title forgot-title">{LOCALE.forgot}</h3>
          <p className="forgot-text">{LOCALE.username_or_email_associated}</p>

          <div className="top-space forgot-msg">
            {fieldEmpty && <div>{LOCALE.please_enter_username}</div>}
            {invalidData && <div>{errorMessage}</div>}
            {internalError && <div>{LOCALE.internal_error}</div>}
          </div>

          <input
            disabled={sendingOTP}
            className={
              fieldEmpty || invalidData || internalError
                ? "input-field input-field-error"
                : "input-field"
            }
            type="text"
            name="username"
            value={username}
            placeholder={LOCALE.username_or_email}
            onChange={this.onInput}
          />

          <div>
            <button
              className={sendingOTP ? "btn1 login-btn-disable" : "btn1"}
              onClick={this.requestOtp}
            >
              {sendingOTP ? "" : LOCALE.reset_password}
            </button>
            {sendingOTP && <div className="lds-dual-ring" />}
          </div>

          <div>
            {LOCALE.back_to_login}
            <button
              className="btn-clear top-space"
              onClick={() => {
                history.goBack();
              }}
            >
              <strong>{LOCALE.click_here}</strong>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const actionCreators = {
  requestOtp: resetPasswordAction.requestOtp,
};

const mapState = (state) => {
  return {
    otpRequestReducer: state.otpRequestReducer,
  };
};

export default connect(mapState, actionCreators)(OtpRequest);
