import {loginConstants} from "../constants/loginConstants";
import {userConstants} from "../constants/userConstants";

const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

const initialState = {
    login: {
        loggingIn: false,
        invalidCredentials: false,
        internalError: false,
        accessToken: accessToken
    },
    facebookLogin: {
        fbLoggingIn: false,
        fbInvalidCredentials: false,
        fbInternalError: false,
        fbAccessToken: accessToken
    },
    logout: {
        loggingOut: false,
        internalError: false,
    }
};

const loginReducer = (state = initialState.facebookLogin, action) => {
    switch (action.type) {
        case loginConstants.LOGIN_REQUEST:
            return {
                ...initialState.login,
                loggingIn: true
            };
        case loginConstants.LOGIN_SUCCESS:
            return {
                ...initialState.login,
                accessToken: action.accessToken
            };
        case loginConstants.LOGIN_FAILURE:
            switch (action.errorCode) {
                case 401:
                    return {
                        ...initialState.login,
                        invalidCredentials: true
                    };
                default:
                    return {
                        ...initialState.login,
                        internalError: true
                    }
            }
        default:
            return state;
    }
};
const facebookLoginReducer = (state = initialState.facebookLogin, action) => {
    switch (action.type) {
        case loginConstants.FACEBOOK_LOGIN_REQUEST:
            return {
                ...initialState.facebookLogin,
                fbLoggingIn: true
            };
        case loginConstants.FACEBOOK_LOGIN_SUCCESS:
            return {
                ...initialState.facebookLogin,
                fbAccessToken: action.accessToken
            };
        case loginConstants.FACEBOOK_LOGIN_FAILURE:
            switch (action.errorCode) {
                case 401:
                    return {
                        ...initialState.facebookLogin,
                        fbInvalidCredentials: true
                    };
                default:
                    return {
                        ...initialState.facebookLogin,
                        fbInternalError: true
                    }
            }
        default:
            return state;
    }
};

const logoutReducer = (state = initialState.logout, action) => {
    switch (action.type) {
        case loginConstants.LOGOUT_REQUEST:
            return {
                ...initialState.logout,
                loggingOut: true
            };
        case loginConstants.LOGOUT_SUCCESS:
            return {
                ...initialState.logout,
                loggingOut: false
            };
        case loginConstants.LOGOUT_FAILURE:
            switch (action.errorCode) {
                case 401:
                    return {
                        ...initialState.logout,
                        invalidCredentials: true,
                        loggingOut: false
                    };
                default:
                    return {
                        ...initialState.logout,
                        internalError: true,
                        loggingOut: false
                    }
            }
        default:
            return state;
    }
};

export {loginReducer, logoutReducer,facebookLoginReducer};
