import { photoConstants } from "../constants/photoConstants";

const initialState = {
  photoState: {
    updatingPhoto: false,
    invalidData: false,
    internalError: false,
    responseData: {},
    errorMessage: "",
  },
  getPhotoState: {
    gettingPhoto: false,
    invalidData: false,
    internalError: false,
    responseData: null,
    errorMessage: "",
  },
};

const photoReducer = (state = initialState.photoState, action) => {
  switch (action.type) {
    case photoConstants.UPDATE_PHOTO_REQUEST:
      return {
        ...initialState.photoState,
        updatingPhoto: true,
      };
    case photoConstants.UPDATE_PHOTO_SUCCESS:
      return {
        ...initialState.photoState,
        responseData: action.responseData,
      };
    case photoConstants.UPDATE_PHOTO_FAILURE:
      switch (action.errorCode) {
        case 400:
        case 401:
        case 404:
          return {
            ...initialState.photoState,
            invalidData: true,
            errorMessage: action.errorMessage,
          };
        default:
          return {
            ...initialState.photoState,
            internalError: true,
          };
      }
    default:
      return state;
  }
};

const getPhotoReducer = (state = initialState.getPhotoState, action) => {
  switch (action.type) {
    case photoConstants.VIEW_PHOTO_REQUEST:
      return {
        ...initialState.getPhotoState,
        gettingPhoto: true,
      };
    case photoConstants.VIEW_PHOTO_SUCCESS:
      return {
        ...initialState.getPhotoState,
        responseData: action.responseData,
      };
    case photoConstants.VIEW_PHOTO_FAILURE:
      switch (action.errorCode) {
        case 400:
        case 401:
        case 404:
          return {
            ...initialState.getPhotoState,
            invalidData: true,
            errorMessage: action.errorMessage,
          };
        default:
          return {
            ...initialState.getPhotoState,
            internalError: true,
          };
      }
    default:
      return state;
  }
};

export { photoReducer, getPhotoReducer };
