import { combineReducers } from "redux";

import {
  loginReducer,
  logoutReducer,
  facebookLoginReducer,
} from "./loginReducer";
import {
  otpRequestReducer,
  otpVerifyReducer,
  resetPasswordReducer,
} from "./resetPasswordReducer";
import {
  otpRequestRegisterReducer,
  otpVerifyRegisterReducer,
  userRegisterReducer,
} from "./registrationReducer";
import {
  fetchNearMeUsersSettingsReducer,
  getSearchUserReducer,
  getTrendingUserReducer,
  getUserReducer,
  nearMeUsersReducer,
  nearMeUsersSettingsReducer,
  reportUserReducer,
  respondUserReducer,
} from "./userReducer";
import { countryReducer } from "./helperReducer";
import {
  getProfileQuestionsReducer,
  getProfileReducer,
  profileReducer,
  getMyGalleryReducer,
  getUserGalleryReducer,
} from "./profileReducer";
import {
  changePasswordReducer,
  requestChangeEmailReducer,
  requestVerifyEmailReducer,
} from "./accountSettingsReducer";
import { getMatchesListReducer } from "./matchReducer";
import {
  checkFriendStatusReducer,
  getFriendsListReducer,
  listenFriendRequestReducer,
  pendingFriendRequestsReducer,
  respondFriendReducer,
  sentFriendsRequestListReducer,
} from "./friendReducer";
import {
  getMembershipPlanDetailsReducer,
  getMembershipPlansReducer,
  purchaseMembershipPlansReducer,
} from "./membershipPlansReducer";
import { getMessageUsersReducer, messageReducer } from "./messageReducer";

import { accessFeatureReducer } from "./featureAccessReducer";

import { notificationReducer } from "./notificationReducer";

import {
  getCountryReducer,
  getStateReducer,
  getCityReducer,
} from "./countryReducer";

import { getPhotoReducer, photoReducer } from "./photoReducer";
import {
  getSentGiftsListReducer,
  getReceivedGiftsListReducer,
  getAllGiftsListReducer,
  sendGiftReducer,
} from "./giftReducer";

import {
  getSentViewsListReducer,
  getReceivedViewsListReducer,
} from "./viewReducer";
import {
  getReceivedWinksListReducer,
  getAllFlirtsReducer,
  sendWinkReducer,
} from "./winkReducer";

const rootReducer = combineReducers({
  loginReducer,
  otpRequestReducer,
  otpVerifyReducer,
  otpRequestRegisterReducer,
  otpVerifyRegisterReducer,
  userRegisterReducer,
  resetPasswordReducer,
  getTrendingUserReducer,
  getUserReducer,
  respondUserReducer,
  countryReducer,
  profileReducer,
  getProfileReducer,
  getProfileQuestionsReducer,
  getSearchUserReducer,
  reportUserReducer,
  requestChangeEmailReducer,
  requestVerifyEmailReducer,
  changePasswordReducer,
  logoutReducer,
  getMatchesListReducer,
  getFriendsListReducer,
  pendingFriendRequestsReducer,
  sentFriendsRequestListReducer,
  respondFriendReducer,
  listenFriendRequestReducer,
  getMembershipPlansReducer,
  purchaseMembershipPlansReducer,
  getMembershipPlanDetailsReducer,
  nearMeUsersReducer,
  nearMeUsersSettingsReducer,
  fetchNearMeUsersSettingsReducer,
  accessFeatureReducer,
  checkFriendStatusReducer,
  getMessageUsersReducer,
  messageReducer,
  notificationReducer,
  facebookLoginReducer,
  getCountryReducer,
  getStateReducer,
  getCityReducer,
  photoReducer,
  getPhotoReducer,
  getSentGiftsListReducer,
  getReceivedGiftsListReducer,
  getSentViewsListReducer,
  getReceivedViewsListReducer,
  getReceivedWinksListReducer,
  getAllGiftsListReducer,
  sendGiftReducer,
  getAllFlirtsReducer,
  sendWinkReducer,
  getMyGalleryReducer,
  getUserGalleryReducer,
});

export default rootReducer;
