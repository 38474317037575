import { apiService } from "../services/apiService";
import { utils } from "../helper/utils";
import { giftsConstants } from "../constants/giftsConstants";
import firebase from "firebase";
import "firebase/database";

export const giftActions = {
  getSentGiftsList,
  getReceivedGiftsList,
  getAllGiftsList,
  sendGifts,
};

function getSentGiftsList() {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getSentGifts()
      .then((response) => {
        console.log("getgifts response=");
        // utils.addCountryStateCityNameToUsers(response.data.data, (users) => {
        //     response.data.data = users;
        //     dispatch(success(response.data, page === 1));
        // });
        var users = response.data.data;
        dispatch(success(response.data.data));
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request(reset) {
    return {
      type: giftsConstants.FETCH_SENT_GIFTS_LISTS_REQUEST,
      reset,
    };
  }

  function success(responseData, reset) {
    return {
      type: giftsConstants.FETCH_SENT_GIFTS_LISTS_SUCCESS,
      responseData: responseData,
      reset,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: giftsConstants.FETCH_SENT_GIFTS_LISTS_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}

function getReceivedGiftsList() {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getReceivedGifts()
      .then((response) => {
        console.log("getgifts response=");
        // utils.addCountryStateCityNameToUsers(response.data.data, (users) => {
        //     response.data.data = users;
        //     dispatch(success(response.data, page === 1));
        // });
        var users = response.data.data;
        dispatch(success(response.data.data));
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request(reset) {
    return {
      type: giftsConstants.FETCH_RECEIVED_GIFTS_LISTS_REQUEST,
      reset,
    };
  }

  function success(responseData, reset) {
    return {
      type: giftsConstants.FETCH_RECEIVED_GIFTS_LISTS_SUCCESS,
      responseData: responseData,
      reset,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: giftsConstants.FETCH_RECEIVED_GIFTS_LISTS_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}

function getAllGiftsList() {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getGifts()
      .then((response) => {
        console.log("getgifts response=");
        // utils.addCountryStateCityNameToUsers(response.data.data, (users) => {
        //     response.data.data = users;
        //     dispatch(success(response.data, page === 1));
        // });
        var users = response.data.data;
        dispatch(success(response.data.data));
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request(reset) {
    return {
      type: giftsConstants.FETCH_ALL_GIFTS_LISTS_REQUEST,
      reset,
    };
  }

  function success(responseData, reset) {
    return {
      type: giftsConstants.FETCH_ALL_GIFTS_LISTS_SUCCESS,
      responseData: responseData,
      reset,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: giftsConstants.FETCH_ALL_GIFTS_LISTS_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}

function sendGifts(idUser, giftId) {
  console.log("gift action", idUser, giftId);

  return (dispatch) => {
    dispatch(request());
    apiService
      .sendGift(idUser, giftId)
      .then((response) => {
        console.log("getgifts response=");
        // utils.addCountryStateCityNameToUsers(response.data.data, (users) => {
        //     response.data.data = users;
        //     dispatch(success(response.data, page === 1));
        // });
        var users = response.data.data;
        dispatch(success(response.data.data));
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request(reset) {
    return {
      type: giftsConstants.SEND_GIFT_FAILURE,
      reset,
    };
  }

  function success(responseData, reset) {
    return {
      type: giftsConstants.SEND_GIFT_SUCCESS,
      responseData: responseData,
      reset,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: giftsConstants.SEND_GIFT_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}
