import LocalizedStrings from "react-localization";
import en from "./en";

const localization = {

    en


};
let strings = new LocalizedStrings(localization);
export default strings;
