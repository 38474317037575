import React, {Component} from 'react';
import Tab from "../tab/tab";
import '../../css/style.css'
import {helperActions} from "../../actions/helperActions";
import {connect} from "react-redux";
import {utils} from "../../helper/utils";
import {history} from "../app/App";
import SideDrawer from "../SideDrawer/SideDrawer";
import {featureAccessAction} from "../../actions/featureAccessAction";
import Loader from "../Loader/Loader";
import {userConstants} from "../../constants/userConstants";
import {default as LOCALE} from "../../localization";

class Search extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchByUsername: false,
            searchByAttributes: true,
            searchParams: {

                seeking: 'M',
                age_to: '',
                age_from: '',
                country_id: '',
                state_id: '',
                city_id: '',
                username: '',
            },
            seeking: 'M',
            countryIndex: 0,
            stateIndex: 0,
            cityIndex: 0,
            emptyUsername: false,
            sideDrawerOpen: false,
            emptyMinAge: false,
            invalidMinAge: false,
            invalidAgeRange: false,
            emptyMaxAge: false
        };
    }


    componentDidMount() {
        console.log('componentDidMount');
        this.props.loadCountryStateCityList();
        this.props.checkFeatureAccess("search");
    }


    onSearchSelected = (value) => {
        if (value === 'attributes') {
            this.setState({
                searchByUsername: false,
                searchByAttributes: true,
                username: ''


            });

        } else {
            this.setState({
                searchByUsername: true,
                searchByAttributes: false,

            });
        }
    };

    populateCountryList = () => {
        const {countryList} = this.props.countryReducer;

        let items = [];
        for (let i = 0; i < countryList.length; i++) {
            items.push(<option key={i} value={i}>{countryList[i]}</option>)
        }
        return items;
    };

    populateStateList = () => {
        const {stateList} = this.props.countryReducer;

        let items = [];
        for (let i = 0; i < stateList.length; i++) {
            items.push(<option key={i} value={i}>{stateList[i]}</option>)
        }
        return items;
    };

    populateCityList = () => {
        const {cityList} = this.props.countryReducer;
        let items = [];
        for (let i = 0; i < cityList.length; i++) {
            items.push(<option key={i} value={i}>{cityList[i]}</option>)
        }
        return items;
    };


    onSeekingSelected = (value) => {
        this.setState({seeking: value})
    };

    onCountrySelected = (selectedCountryIndex) => {
        this.props.loadStateCityList(selectedCountryIndex);
        this.setState({countryIndex: selectedCountryIndex, stateIndex: 0, cityIndex: 0})
    };

    onStateSelected = (selectedStateIndex) => {
        const {countryIndex} = this.state;
        this.props.loadCityList(countryIndex, selectedStateIndex);
        this.setState({stateIndex: selectedStateIndex, cityIndex: 0})
    };

    onCitySelected = (selectedCityIndex) => {
        this.setState({cityIndex: selectedCityIndex})
    };


    searchByAttributes = async () => {
        const {seeking, countryIndex, stateIndex, cityIndex} = this.state;
        const {countryId, stateId, cityId} = await utils.getCountryStateCityIds(countryIndex, stateIndex, cityIndex);
        const {age_from, age_to} = this.state.searchParams;

        console.log('createProfile called', countryId, stateId, cityId, seeking);

        this.setState({
            emptyMinAge: false,
            emptyMaxAge: false,
            invalidMinAge: false,
            invalidAgeRange: false

        });
        if (age_from.length <= 0) {
            this.setState({
                emptyMinAge: true
            });
            return
        }
        if (age_to.length <= 0) {
            this.setState({
                emptyMaxAge: true
            });
            return
        }
        if (parseInt(age_from) < 18) {
            this.setState({
                invalidMinAge: true
            });
            return
        }
        if (parseInt(age_to) < parseInt(age_from)) {
            this.setState({
                invalidAgeRange: true
            });
            return
        }
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                seeking: seeking,
                country_id: countryId,
                state_id: stateId,
                city_id: cityId,
                username: ''
            }
        });
        console.log('updateProfileQuestions=', this.state.searchParams);

        history.push('/searchResults', {searchParams: this.state.searchParams, searchType: "basic"})
    };

    searchByUsername = () => {
        const {username} = this.state.searchParams;
        if (username.length <= 0 || username.empty) {
            this.setState({
                emptyUsername: true
            });
            return
        }

        this.setState({
            searchParams: {
                ...this.state.searchParams,
            },
            emptyUsername: false

        });

        history.push('/searchResults', {searchParams: this.state.searchParams, searchType: "basic"})
    };


    isUsernameEmpty = (username) => {


    };
    onInput = (e) => {
        const {name, value} = e.target;
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                [name]: value
            }
        });
    };


    drawerToggleClickHandler = () => {
        this.setState(prevState => {
            return {sideDrawerOpen: !prevState.sideDrawerOpen}
        })
    };

    dismissSideDrawer = () => {
        this.setState({sideDrawerOpen: false})
    };


    render() {
        LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        const {countryIndex, stateIndex, cityIndex, seeking, emptyUsername, emptyMinAge, emptyMaxAge, invalidMinAge, invalidAgeRange} = this.state;
        const {username, age_from, age_to} = this.state.searchParams;
        const {verifyingAccess, access, internalError, errorMessage} = this.props.accessFeatureReducer;
        const {searchByUsername, searchByAttributes, sideDrawerOpen} = this.state;
        console.log("the is one ", localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        return (
            <div className='home-page-wrapper search-page'>
                <div className='home-page'>
                    {!access ? <div>
                            {!verifyingAccess ? <div className="homepage-content">
                                    <h3>{LOCALE.please_subscribe_our_feature}</h3>
                                    <button className='btn1' onClick={() => {
                                        history.push("/membershipPlans")
                                    }}>{LOCALE.take_me_to_plans}
                                    </button>
                                    {internalError && <div>{errorMessage}</div>}
                                </div> :
                                <div>
                                    <Loader/>
                                </div>
                            }
                        </div> :
                        <div className="homepage-content">
                            <div>
                                <h1 className="login-page-title">{LOCALE.search}</h1>

                                <div className="text-left">
                                    <label className="radio-btn"> {LOCALE.attributes} <input type="radio" name="search"
                                                                                             value="attributes"
                                                                                             onChange={(e) => {
                                                                                                 this.onSearchSelected(e.target.value)
                                                                                             }}
                                                                                             defaultChecked/>
                                        <span className="checkmark"/>
                                    </label>

                                    <label className="radio-btn"> {LOCALE.username} <input type="radio" name="search"
                                                                                           value="username"
                                                                                           onChange={(e) => {
                                                                                               this.onSearchSelected(e.target.value)
                                                                                           }}/>
                                        <span className="checkmark"/>
                                    </label>

                                </div>

                                {searchByUsername &&
                                <div className="search-username">
                                    <label>{LOCALE.usernames}</label>
                                    {emptyUsername &&
                                    <div className="invalid-age"> {LOCALE.enter_username_to_continue}</div>}
                                    <input className={emptyUsername ? "input-field input-field-error" : "input-field"}
                                           type='text'
                                           name='username'
                                           value={username}
                                           placeholder={LOCALE.username}
                                           onChange={this.onInput}/>
                                </div>
                                }
                            </div>

                            {searchByAttributes &&
                            <div className="search-basic-info">

                                <label>{LOCALE.seeking}</label>
                                <div id="basic-select">
                                    <select className="input-field" value={seeking} onChange={(e) => {
                                        this.onSeekingSelected(e.target.value)
                                    }}>
                                        <option value="M">{LOCALE.male}</option>
                                        <option value="F">{LOCALE.female}</option>
                                        <option value="C">{LOCALE.couples}</option>
                                    </select>
                                </div>
                                <label>{LOCALE.age}</label>
                                <div className="age-bar">
                                    {invalidMinAge && <div className="invalid-age">{LOCALE.minAge} <br/></div>}
                                    {emptyMinAge && <div className="invalid-age">{LOCALE.minageRequired} <br/></div>}
                                    {emptyMaxAge && <div className="invalid-age"><br/>{LOCALE.maxageRequired}</div>}
                                    {invalidAgeRange &&
                                    <div className="invalid-age"><br/></div>}
                                    <input
                                        className={invalidMinAge || emptyMinAge || invalidAgeRange ? "input-field input-field-error" : "input-field"}
                                        type="number" name='age_from' value={age_from}
                                        placeholder={LOCALE.min_age}
                                        onChange={this.onInput}/>
                                    <label>{LOCALE.to}</label>
                                    <input
                                        className={emptyMaxAge || invalidAgeRange ? "input-field input-field-error" : "input-field"}
                                        type="number" name='age_to' value={age_to}
                                        placeholder={LOCALE.max_age}
                                        onChange={this.onInput}/>
                                </div>

                                <label>{LOCALE.country}<span>*</span></label>
                                <div id="basic-select">
                                    <select className="input-field" value={countryIndex} onChange={(e) => {
                                        this.onCountrySelected(e.target.value)
                                    }}>
                                        {this.populateCountryList()}
                                    </select>
                                </div>

                                <label>{LOCALE.state}<span>*</span></label>
                                <div id="basic-select">
                                    <select className="input-field" value={stateIndex} onChange={(e) => {
                                        this.onStateSelected(e.target.value)
                                    }}>
                                        {this.populateStateList()}
                                    </select>
                                </div>

                                <label>{LOCALE.city}<span>*</span></label>
                                <div id="basic-select">
                                    <select className="input-field" value={cityIndex} onChange={(e) => {
                                        this.onCitySelected(e.target.value)
                                    }}>
                                        {this.populateCityList()}
                                    </select>
                                </div>
                            </div>
                            }
                            <div>
                                <button className='btn1'
                                        onClick={searchByAttributes ? this.searchByAttributes : this.searchByUsername}>{LOCALE.search}
                                </button>
                            </div>
                        </div>
                    }
                    <div className="homepage-content2">
                        <SideDrawer show={sideDrawerOpen} onOutsideClick={this.dismissSideDrawer}/>
                    </div>

                    <div className='tab-footer'>
                        <Tab drawerClickHandler={this.drawerToggleClickHandler}/>
                    </div>
                </div>
            </div>
        );
    }
}


const mapState = (state) => {
    return {
        countryReducer: state.countryReducer,
        accessFeatureReducer: state.accessFeatureReducer,
    };
};

const actionCreators = {
    loadCountryStateCityList: helperActions.loadCountryStateCityList,
    loadStateCityList: helperActions.loadStateCityList,
    loadCityList: helperActions.loadCityList,
    checkFeatureAccess: featureAccessAction.checkFeatureAccess,
};

export default connect(mapState, actionCreators)(Search);
