import React, {Component} from 'react';
import {accountSettingsAction} from "../../actions/accountSettingsAction";
import {connect} from "react-redux";
import {history} from "../app/App";
import {default as LOCALE} from "../../localization";
import {userConstants} from "../../constants/userConstants";

class ChangeEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            emptyField: false,
            validEmail: true
        };


    }

    sendOtp = () => {

        const {email} = this.state;
        console.log("the length isa", email.length);
        if (email.length <= 0) {
            this.setState({fieldEmpty: true});
            return;
        }

        this.setState({fieldEmpty: false});

        if (!this.isValidEmail(email)) {
            this.setState({validEmail: false});
            return;
        }
        this.setState(
            {
                fieldEmpty: false,
                validEmail: true,
            }
        );
        this.props.changeEmail(email)
    };


    isValidEmail = (userEmail) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(userEmail);
    };

    onInput = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value});
    };

    render() {
        LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        const {email, fieldEmpty, validEmail} = this.state;
        const {sendingOtp, invalidData, internalError, errorMessage} = this.props.requestChangeEmailReducer;
        return (
            <div>
                <div className="login-page update-email">
                    <div className="login-form">
                        <h6>{LOCALE.update_email}</h6>


                        <div className="top-space forgot-msg">
                            {fieldEmpty && <div>{LOCALE.please_enter_email_to_continue}</div>}
                            {invalidData && <div>{errorMessage}</div>}
                            {!validEmail && <div>{LOCALE.please_enter_valid_email_to_continue}</div>}
                            {internalError && <div>{LOCALE.internal_error}</div>}
                        </div>

                        <input disabled={sendingOtp}
                               className={fieldEmpty || invalidData || internalError ? "input-field input-field-error" : "input-field"}
                               type='email'
                               name='email'
                               value={email}
                               placeholder={LOCALE.email_address}
                               onChange={this.onInput}/>


                        <div>
                            <button className={sendingOtp ? "btn1 login-btn-disable" : "btn1"}
                                    onClick={this.sendOtp}>{sendingOtp ? "" : LOCALE.send_otp}
                            </button>
                            {sendingOtp && <div className="lds-dual-ring"/>}
                        </div>

                        <div>{LOCALE.back_to_settings}
                            <button className="btn-clear top-space"
                                    onClick={() => {
                                        history.goBack();
                                    }}><strong>{LOCALE.click_here}</strong>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const actionCreators = {
    changeEmail: accountSettingsAction.changeEmail

};
const mapState = (state) => {
    return {

        requestChangeEmailReducer: state.requestChangeEmailReducer
    }
};
export default connect(mapState, actionCreators)(ChangeEmail);
