export const userConstants = {
    ACCESS_TOKEN: 'ACCESS_TOKEN',
    USER_ID: 'USER_ID',
    FCM_TOKEN: 'FCM_TOKEN',
    LANGUAGE_DEFAULT: 'LANGUAGE_DEFAULT',

    GET_USERS_REQUEST: 'GET_USERS_REQUEST',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
    GET_USERS_FAILURE: 'GET_USERS_FAILURE',

    RESPOND_USER_REQUEST: 'RESPOND_USER_REQUEST',
    RESPOND_USER_SUCCESS: 'RESPOND_USER_SUCCESS',
    RESPOND_USER_FAILURE: 'RESPOND_USER_FAILURE',

    RESPOND_USER_TYPE_YES: 'RESPOND_USER_TYPE_YES',
    RESPOND_USER_TYPE_NO: 'RESPOND_USER_TYPE_NO',

    GET_TRENDING_USERS_REQUEST: 'GET_TRENDING_USERS_REQUEST',
    GET_TRENDING_USERS_SUCCESS: 'GET_TRENDING_USERS_SUCCESS',
    GET_TRENDING_USERS_FAILURE: 'GET_TRENDING_USERS_FAILURE',

    SEARCH_USER_REQUEST: 'SEARCH_USER_REQUEST',
    SEARCH_USER_SUCCESS: 'SEARCH_USER_SUCCESS',
    SEARCH_USER_FAILURE: 'SEARCH_USER_FAILURE',

    REPORT_USER_REQUEST: 'REPORT_USER_REQUEST',
    REPORT_USER_SUCCESS: 'REPORT_USER_SUCCESS',
    REPORT_USER_FAILURE: 'REPORT_USER_FAILURE',

    NEAR_ME_USER_REQUEST: 'NEAR_ME_USER_REQUEST',
    NEAR_ME_USER_SUCCESS: 'NEAR_ME_USER_SUCCESS',
    NEAR_ME_USER_FAILURE: 'NEAR_ME_USER_FAILURE',

    NEAR_ME_USER_SETTINGS_REQUEST: 'NEAR_ME_USER_SETTINGS_REQUEST',
    NEAR_ME_USER_SETTINGS_SUCCESS: 'NEAR_ME_USER_SETTINGS_SUCCESS',
    NEAR_ME_USER_SETTINGS_FAILURE: 'NEAR_ME_USER_SETTINGS_FAILURE',

    GET_NEAR_ME_USER_SETTINGS_REQUEST: 'GET_NEAR_ME_USER_SETTINGS_REQUEST',
    GET_NEAR_ME_USER_SETTINGS_SUCCESS: 'GET_NEAR_ME_USER_SETTINGS_SUCCESS',
    GET_NEAR_ME_USER_SETTINGS_FAILURE: 'GET_NEAR_ME_USER_SETTINGS_FAILURE',
};
