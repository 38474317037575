import React, { Component } from "react";
import { default as LOCALE } from "../../../localization";
import { userConstants } from "../../../constants/userConstants";

class FreeTextQuestion extends Component {
  constructor(props) {
    super(props);
    console.log("free text", this.props.question);
    //initialization
    const {
      id: profile_setup_id,
      option_value,
      required,
    } = this.props.question;

    if (required === "Y" && !option_value) {
      this.props.onInputError(profile_setup_id, true);
      this.state = { inputError: true };
    } else {
      this.state = { inputError: false };
    }
  }

  onAnswerChanged = (value) => {
    const {
      id: profile_setup_id,
      field_type_id,
      required,
    } = this.props.question;

    if (required === "Y" && !value) {
      this.setState({ inputError: true });
      this.props.onInputError(profile_setup_id, true);
      return;
    }

    this.setState({ inputError: false });
    this.props.onInputError(profile_setup_id, false);

    this.props.onAnswerChanged(profile_setup_id, {
      field_type_id,
      profile_setup_id,
      question_option_id: 0,
      option_value: value,
    });
  };

  render() {
    LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
    const { name, option_value, max_length, required } = this.props.question;
    const { inputError } = this.state;
    console.log("free here", this.props.question);

    return (
      <div className="edit-questions-text">
        {inputError && (
          <div>
            {name} {LOCALE.field_cannot_be_empty}
          </div>
        )}
        <label>
          {name}
          {required === "Y" ? "*" : ""}
        </label>
        <br />
        <textarea
          className={inputError ? "input-field-error" : ""}
          maxLength={max_length}
          onChange={(e) => {
            this.onAnswerChanged(e.target.value);
          }}
          defaultValue={this.props.question.options[0].option_value}
        />
      </div>
    );
  }
}

export default FreeTextQuestion;
