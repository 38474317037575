export const giftsConstants = {
  FETCH_SENT_GIFTS_LISTS_REQUEST: "FETCH_SENT_GIFTS_LISTS_REQUEST",
  FETCH_SENT_GIFTS_LISTS_SUCCESS: "FETCH_SENT_GIFTS_LISTS_SUCCESS",
  FETCH_SENT_GIFTS_LISTS_FAILURE: "FETCH_SENT_GIFTS_LISTS_FAILURE",

  FETCH_RECEIVED_GIFTS_LISTS_REQUEST: "FETCH_RECEIVED_GIFTS_LISTS_REQUEST",
  FETCH_RECEIVED_GIFTS_LISTS_SUCCESS: "FETCH_RECEIVED_GIFTS_LISTS_SUCCESS",
  FETCH_RECEIVED_GIFTS_LISTS_FAILURE: "FETCH_RECEIVED_GIFTS_LISTS_FAILURE",

  FETCH_ALL_GIFTS_LISTS_REQUEST: "FETCH_ALL_GIFTS_LISTS_REQUEST",
  FETCH_ALL_GIFTS_LISTS_SUCCESS: "FETCH_ALL_GIFTS_LISTS_SUCCESS",
  FETCH_ALL_GIFTS_LISTS_FAILURE: "FETCH_ALL_GIFTS_LISTS_FAILURE",

  SEND_GIFT_REQUEST: "SEND_GIFT_REQUEST",
  SEND_GIFT_SUCCESS: "SEND_GIFT_SUCCESS",
  SEND_GIFT_FAILURE: "SEND_GIFT_FAILURE",

  RESPOND_GIFT_REQUEST: "RESPOND_GIFT_REQUEST",
  RESPOND_GIFT_SUCCESS: "RESPOND_GIFT_SUCCESS",
  RESPOND_GIFT_FAILURE: "RESPOND_GIFT_FAILURE",
};
