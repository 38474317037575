import { countryConstants } from "../constants/countryConstants";

const initialState = {
  getCountryState: {
    gettingCountry: false,
    invalidData: false,
    internalError: false,
    responseData: null,
    errorMessage: "",
  },
  getStateState: {
    gettingState: false,
    invalidData: false,
    internalError: false,
    responseData: null,
    errorMessage: "",
  },
  getCityState: {
    gettingCity: false,
    invalidData: false,
    internalError: false,
    responseData: null,
    errorMessage: "",
  },
};

const getCountryReducer = (state = initialState.getCountryState, action) => {
  console.log("country action", action);
  switch (action.type) {
    case countryConstants.GET_COUNTRY_REQUEST:
      return {
        ...initialState.getCountryState,
        gettingCountry: true,
      };
    case countryConstants.GET_COUNTRY_SUCCESS:
      return {
        ...initialState.getCountryState,
        responseData: action,
      };
    case countryConstants.GET_COUNTRY_FAILURE:
      switch (action.errorCode) {
        case 400:
        case 401:
        case 404:
          return {
            ...initialState.getCountryState,
            invalidData: true,
            errorMessage: action.errorMessage,
          };
        default:
          return {
            ...initialState.getCountryState,
            internalError: true,
          };
      }
    default:
      return state;
  }
};

const getStateReducer = (state = initialState.getStateState, action) => {
  switch (action.type) {
    case countryConstants.GET_STATE_REQUEST:
      return {
        ...initialState.getStateState,
        gettingState: true,
      };
    case countryConstants.GET_STATE_SUCCESS:
      return {
        ...initialState.getStateState,
        responseData: action.responseData,
      };
    case countryConstants.GET_STATE_FAILURE:
      switch (action.errorCode) {
        case 400:
        case 401:
        case 404:
          return {
            ...initialState.getStateState,
            invalidData: true,
            errorMessage: action.errorMessage,
          };
        default:
          return {
            ...initialState.getStateState,
            internalError: true,
          };
      }
    default:
      return state;
  }
};

const getCityReducer = (state = initialState.getCityState, action) => {
  switch (action.type) {
    case countryConstants.GET_CITY_REQUEST:
      return {
        ...initialState.getCityState,
        gettingCity: true,
      };
    case countryConstants.GET_CITY_SUCCESS:
      return {
        ...initialState.getCityState,
        responseData: action.responseData,
      };
    case countryConstants.GET_CITY_FAILURE:
      switch (action.errorCode) {
        case 400:
        case 401:
        case 404:
          return {
            ...initialState.getCityState,
            invalidData: true,
            errorMessage: action.errorMessage,
          };
        default:
          return {
            ...initialState.getCityState,
            internalError: true,
          };
      }
    default:
      return state;
  }
};

export { getCountryReducer, getStateReducer, getCityReducer };
