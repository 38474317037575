import React, {Component} from 'react';
import {userActions} from "../../actions/userActions";
import {connect} from "react-redux";
import {history} from "../app/App";
import {default as LOCALE} from "../../localization";
import {userConstants} from "../../constants/userConstants";

class NearMeSettings extends Component {
    constructor(props) {
        super(props);
        const {initialData} = this.props.location.state;
        this.state = {
            units: initialData.unit,
            value: initialData.value,
            unitEmpty: false,
            valueEmpty: false
        }
    }

    onInput = (e) => {
        const {name, value} = e.target;
        this.setState({
            [name]: value
        });
    };

    saveSettings = () => {
        const {units, value} = this.state;

        this.props.nearMeUsersSettings(units, value);
    };

    render() {
        LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        const {units, value, unitEmpty, valueEmpty} = this.state;
        const {savingSettings, invalidData, internalError, errorMessage} = this.props.nearMeUsersSettingsReducer;

        return (
            <div className="home-page-wrapper near-me-page">
                <div className="home-page">
                    <div className="top-bar">
                        <img onClick={() => {
                            history.goBack();
                        }} src={require('../../img/back.png')} alt="back-button"/>
                        <h3>{LOCALE.settings}</h3>
                    </div>

                    <div className="homepage-content">

                        <div className="text-left">
                            {unitEmpty && <div>{LOCALE.units_required}</div>}
                            <label className="text-left">{LOCALE.units}</label>
                            <label className="radio-btn"> {LOCALE.kilometers} <input type="radio" name="units" value="kilometers"
                                                                            checked={units === 'kilometers'}
                                                                            onChange={this.onInput}/>
                                <span className="checkmark"></span>
                            </label>

                            <label className="radio-btn"> {LOCALE.miles} <input type="radio" name="units" value="miles"
                                                                       checked={units === 'miles'}
                                                                       onChange={this.onInput}/>
                                <span className="checkmark"></span>
                            </label>
                            <br/>
                            <br/>
                        </div>
                        {valueEmpty && <div>{LOCALE.values_required}</div>}

                        <div className="text-left value-setting">
                            <label>{LOCALE.value}<span> :</span></label>
                            <input className="input-field" type="number" name="value" value={value} onChange={this.onInput}/>
                            {invalidData && <div>{errorMessage}</div>}
                            {internalError && <div>{LOCALE.internal_error}</div>}
                            <button className="btn1"
                                disabled={savingSettings}
                                onClick={this.saveSettings}>
                                {savingSettings ? LOCALE.updating_settings : LOCALE.save_settings}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapState = (state) => {
    return {
        nearMeUsersSettingsReducer: state.nearMeUsersSettingsReducer,
    }
};
const actionCreators = {
    nearMeUsersSettings: userActions.nearMeUsersSettings,
};

export default connect(mapState, actionCreators)(NearMeSettings);
