import axios from "axios";
import { userConstants } from "../constants/userConstants";
import { friendsConstants } from "../constants/friendsConstants";

export const apiService = {
  login,
  logout,
  sendOTP,
  verifyOTP,
  sendOTPRegistration,
  verifyOTPRegistration,
  userRegistration,
  resetPassword,
  getUsers,
  respondUser,
  getTrendingUsers,
  updateProfile,
  getMyProfile,
  getProfile,
  getProfileQuestions,
  updateProfileQuestions,
  getSearchUsers,
  reportUser,
  changeEmail,
  verifyEmail,
  changePassword,
  getMatchesList,
  getFriendsList,
  sentFriendsRequestList,
  pendingFriendRequests,
  respondFriend,
  getMembershipPlansList,
  purchaseMembershipPlan,
  getMembershipPlanDetails,
  getNearMe,
  updateNearMeSettings,
  getNearMeSettings,
  getMessageUsers,
  getMessages,
  sendMessage,
  checkFriendStatus,
  checkFeatureAccess,
  loginWithFacebook,
  invalidateFacebookLogin,
  // checkVersion,
  getCountries,
  getStates,
  getCities,
  getPhoto,
  updatePhoto,
  checkMyProfile,
  getSentGifts,
  getReceivedGifts,
  viewedMe,
  iViewed,
  receivedWinks,
  getGifts,
  sendGift,
  getFlirts,
  sendWink,
  getMyGallery,
  getUserGallery,
};

// const base_url = `https://cors-anywhere.herokuapp.com/${process.env.REACT_APP_BASE_URL}`;
const base_url = process.env.REACT_APP_BASE_URL;

const client_id = process.env.REACT_APP_CLIENT_ID;
const client_secret = process.env.REACT_APP_CLIENT_SECRET;

var selfId;

function login(username, password) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
    },
  };
  const body = {
    grant_type: "password",
    username: username,
    password: password,
    client_id: client_id,
    client_secret: client_secret,
  };

  const request = axios.post(`${base_url}/oauth/token`, body, config);

  console.log(request);

  return request;
}

function sendOTP(username) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
    },
  };
  const body = {
    username: username,
  };

  const request = axios.post(
    `${base_url}/wp-json/api/v1/users/reset/password`,
    body,
    config
  );

  return request;
}

function verifyOTP(otpCode, insertId) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
    },
  };
  const body = {
    code: otpCode,
  };

  const request = axios.post(
    `${base_url}/wp-json/wpdating/v1/password/forgot/${insertId}`,
    body,
    config
  );
  return request;
}

function resetPassword(newPassword, userId) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
    },
  };

  const body = {
    password: newPassword,
  };

  const request = axios.post(
    `${base_url}/wp-json/wpdating/v1/password/reset/${userId}`,
    body,
    config
  );
  return request;
}

function sendOTPRegistration(userEmail) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
    },
  };
  const body = {
    email: userEmail,
  };

  const request = axios.post(
    `${base_url}/wp-json/api/v1/email/sendCode`,
    body,
    config
  );

  return request;
}

function verifyOTPRegistration(otpCode, insertId) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
    },
  };

  const body = {
    verification_code: otpCode,
    email: insertId,
  };

  console.log("insert", body);

  const request = axios.post(
    `${base_url}/wp-json/api/v1/email/verifyCode`,
    body,
    config
  );
  return request;
}

function userRegistration(params) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
    },
  };

  const body = new FormData();
  body.append("username", params?.username);
  body.append("first_name", params?.first_name);
  body.append("last_name", params?.last_name);
  body.append("email", params?.email);
  body.append("password", params?.password);
  body.append("fcm_registration_id", "");

  const request = axios.post(`${base_url}/wp-json/api/v1/users`, body, config);

  console.log("userRegistration request=", request);

  return request;
}

function getUsers(page = 1) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const request = axios.get(
    `${base_url}/wp-json/api/v1/meetme/getUsers?page=${page}`,
    config
  );

  return request;
}

function respondUser(userId, status) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const body = {
    user_id: userId,
    status: status,
  };

  console.log("swipe body", body);
  const request = axios.post(
    `${base_url}/wp-json/api/v1/meetme/swipe`,
    body,
    config
  );

  console.log("respondUser request=", request);

  return request;
}

function getTrendingUsers() {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const request = axios.get(
    `${base_url}/wp-json/api/v1/trending/users?filter_option=WINK`,
    config
  );

  return request;
}

function updateProfile(
  image,
  gender,
  age,
  seeking,
  countryIndex,
  stateIndex,
  cityIndex,
  aboutMe,
  myInterest,
  createNew = false
) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  console.log(
    "updateProfile body=",
    image,
    gender,
    age,
    seeking,
    countryIndex,
    stateIndex,
    cityIndex,
    aboutMe,
    myInterest,
    createNew
  );

  const body = new FormData();
  body.append("seeking", seeking);
  for (const pair of body.entries()) {
    console.log("hello form", `${pair[0]}, ${pair[1]}`);
  }

  // const body = {};
  // body.image = image;
  // body.seeking = seeking;
  // body.gender = gender;
  // body.age = age;
  // body.country_id = countryIndex;
  // body.state_id = stateIndex;
  // body.city_id = cityIndex;
  // body.about_me = aboutMe;
  // body.my_interest = myInterest;

  if (createNew) {
    body.append("image_orig", image.image_orig, "image_orig.jpeg");
    body.append("image_350", image.image_350, "image_350.jpeg");
    body.append("image_250", image.image_250, "image_250.jpeg");
    body.append("seeking", seeking);
    body.append("gender", gender);
    body.append("age", age);
    body.append("country_id", countryIndex);
    body.append("state_id", stateIndex);
    body.append("city_id", cityIndex);
    body.append("about_me", aboutMe);
    body.append("my_interest", myInterest);
  } else {
    if (typeof image.image_orig != "string") {
      body.append("image_orig", image.image_orig, "image_orig.jpeg");
      body.append("image_350", image.image_350, "image_350.jpeg");
      body.append("image_250", image.image_250, "image_250.jpeg");
    }
    if (seeking) {
      body.append("seeking", seeking);
    }
    if (gender) {
      body.append("gender", gender);
    }
    if (age) {
      body.append("age", age);
    }
    if (countryIndex) {
      body.append("country_id", countryIndex);
    }
    if (stateIndex === 0 || stateIndex) {
      body.append("state_id", stateIndex);
    }
    if (cityIndex) {
      body.append("city_id", cityIndex);
    }
    if (aboutMe) {
      body.append("about_me", aboutMe);
    }
    if (myInterest) {
      body.append("my_interest", myInterest);
    }
  }

  // var bodyFormData = new FormData();
  // bodyFormData.append('image', image);

  const config = {
    headers: {
      "Content-Type": `multipart/form-data; boundary=${body._boundary}`,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  let request;
  if (createNew) {
    console.log("else updates body", body);

    request = axios.post(
      `${base_url}/wp-json/api/v1/user/profiles/create/me`,
      body,
      config
    );
  } else {
    console.log("updates body", body);
    request = axios.post(
      `${base_url}/wp-json/api/v1/user/profiles/update/me`,
      body,
      config
    );
  }

  console.log("updateProfile request=", request);

  return request;
}

const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
console.log("tokensss", accessToken);

function getMyProfile() {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // const request = axios.get(
  //   `${base_url}/wp-json/api/v1/user/profiles/me`,
  //   config
  // );

  const request = axios.get(`${base_url}/wp-json/api/v1/users/me`, config);

  // const request = axios.get(
  //   `${base_url}/wp-json/api/v1/meetme/getCurrentUser`,
  //   config
  // );

  return request;
}

function checkMyProfile() {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // const request = axios.get(
  //   `${base_url}/wp-json/api/v1/user/profiles/me`,
  //   config
  // );

  const request = axios.get(
    `${base_url}/wp-json/api/v1/user/profiles/me`,
    config
  );

  // const request = axios.get(
  //   `${base_url}/wp-json/api/v1/meetme/getCurrentUser`,
  //   config
  // );

  return request;
}

function getProfile(userId) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // let request = axios.get(
  //   `${base_url}/wp-json/api/v1/user/profiles/${userId}`,
  //   config
  // );

  if (userId == "me") {
    let request = axios.get(
      `${base_url}/wp-json/api/v1/meetme/getCurrentUser`,
      config
    );
    console.log("getProfile request=", request);

    return request;
  } else {
    let request = axios.get(
      `${base_url}/wp-json/api/v1/meetme/getUser/${userId}`,
      config
    );
    console.log("getProfile request=", request);

    return request;
  }

  // console.log("getProfile request=", request);

  // return request;
}

async function getPhoto() {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const request = await axios.get(
    `${base_url}/wp-json/api/v1/user/photos/me`,
    config
  );
  console.log("getMemberPhoto request=", request);
  return request;
}

async function getProfileQuestions() {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const request = await axios.get(
    `${base_url}/wp-json/api/v1/user/profile-questions/me`,
    config
  );
  console.log("getProfileQuestions request=", request);
  return request;
}

function updatePhoto(image) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
  // console.log("update photo", image);

  const body = new FormData();

  body.append("image_orig", image.img_orig);
  body.append("image_250", image.img_250);
  body.append("image_350", image.img_350);
  body.append("type", "image/jpeg");

  // console.log("photo body", body);

  const config = {
    headers: {
      "Content-Type": `multipart/form-data; boundary=${body._boundary}`,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const request = axios.post(
    `${base_url}/wp-json/api/v1/user/photos/create/me`,
    body,
    config
  );

  return request;
}

function updateProfileQuestions(profileQuestions) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
  /*

        profileQuestions = [{
            "field_type_id": "1",
            "profile_setup_id": "16",
            "question_option_id": "0",
            "option_value": ""
        }];
    */

  console.log("question profile", profileQuestions);

  const body = {
    profile_questions: profileQuestions,
  };

  console.log("updateProfileQuestions body=", body);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const request = axios.post(
    `${base_url}/wp-json/api/v1/user/profile-questions/update/me`,
    body,
    config
  );

  console.log("updateProfileQuestions request=", request);

  return request;
}

function getSearchUsers(searchParams, searchType, page = 1) {
  console.log("in API search", searchParams);
  let answers = [];
  for (const key in searchParams.additional_options) {
    if (searchParams.additional_options.hasOwnProperty(key)) {
      const { answer } = searchParams.additional_options[key];
      for (const question_id of answer.options) {
        answers.push(question_id.question_option_id);
      }
    }
  }

  // searchParams = {
  //   ...searchParams,
  //   country_id: searchParams.country_id.toString(),
  //   state_id: searchParams.state_id.toString(),
  //   city_id: searchParams.city_id.toString(),
  //   additional_options: "[" + answers.toString() + "]",
  // };

  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
  console.log("those are ", searchParams, searchType);
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  let request = axios.get(
    `${base_url}/wp-json/api/v1/search/users?age_from=${searchParams.age_from}&age_to=${searchParams.age_to}&country_id=${searchParams.country_id}&state_id=${searchParams.state_id}&city_id=${searchParams.city_id}&seeking=${searchParams.seeking}`,
    config,
    searchParams
  );

  console.log("getSearchUsers request=", searchParams);

  return request;
}

function reportUser(reportMessage, userId) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const body = {
    reason: reportMessage,
  };

  const request = axios.post(
    `${base_url}/wp-json/wpdating/v1/report-user/${userId}`,
    body,
    config
  );

  return request;
}

function changeEmail(userEmail) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const body = {
    email: userEmail,
  };

  const request = axios.post(
    `${base_url}/wp-json/api/v1/email/sendCode`,
    body,
    config
  );

  return request;
}

function verifyEmail(insertId, code) {
  console.log("in verify", insertId);
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const body = {
    verification_code: code,
    email: insertId,
  };

  const request = axios.post(
    `${base_url}/wp-json/api/v1/email/verifyCode`,
    body,
    config
  );

  return request;
}

function changePassword(oldPassword, newPassword) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const body = {
    old_password: oldPassword,
    new_password: newPassword,
  };

  const request = axios.post(
    `${base_url}/wp-json/api/v1/users/change/password`,
    body,
    config
  );

  return request;
}

function logout() {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
    },
  };
  let request = axios.post(
    `${base_url}/oauth/destroy?access_token=${accessToken}`,
    config
  );

  return request;
}

function getFriendsList(page = 1) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  let request = axios.get(
    `${base_url}/wp-json/api/v1/friends/me?page=${page}`,
    config
  );

  console.log("getFriendList request=", request);

  return request;
}

function getMatchesList(page = 1) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  let request = axios.get(
    `${base_url}/wp-json/api/v1/my-matches/getUsers?page=${page}`,
    config
  );

  console.log("getMatchList request=", request);

  return request;
}

function sentFriendsRequestList(page = 1) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  let request = axios.get(
    `${base_url}/wp-json/api/v1/friend-requests/me?type=OUTGOING`,
    config
  );

  console.log("getFriendList request=", request);

  return request;
}

function pendingFriendRequests(page = 1) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  let request = axios.get(
    `${base_url}/wp-json/api/v1/friend-requests/me?type=INCOMING`,
    config
  );

  console.log("getFriendList request=", request);

  return request;
}

function respondFriend(id, operation) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  let body;
  let request;
  switch (operation) {
    case friendsConstants.FRIEND_SEND_REQUEST:
      body = {
        friend_uid: id,
      };
      request = axios.post(
        `${base_url}/wp-json/api/v1/friend-requests/send`,
        body,
        config
      );
      break;

    case friendsConstants.FRIEND_CANCEL_REQUEST:
      body = {
        friend_request_id: id,
        status: "CANCELLED",
      };
      request = axios.post(
        `${base_url}/wp-json/api/v1/friend-requests/update/${id}`,
        body,
        config
      );
      break;
    case friendsConstants.FRIEND_ACCEPT_REQUEST:
      body = {
        friend_request_id: id,
        status: "ACCEPTED",
      };
      request = axios.post(
        `${base_url}/wp-json/api/v1/friend-requests/update/${id}`,
        body,
        config
      );
      break;
    case friendsConstants.FRIEND_DECLINE_REQUEST:
      body = {
        friend_request_id: id,
        status: "REJECTED",
      };
      request = axios.post(
        `${base_url}/wp-json/api/v1/friend-requests/update/${id}`,
        body,
        config
      );
      break;
    case friendsConstants.FRIEND_UNFRIEND:
      request = axios.delete(
        `${base_url}/wp-json/api/v1/friends/${id}`,
        config
      );
      break;
    default:
      break;
  }

  console.log("respondFriend().operation=", operation);
  console.log("addFriendRequest request=", request);

  return request;
}

function getMembershipPlansList() {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  let request = axios.get(`${base_url}/wp-json/api/v1/plans`, config);

  console.log("getmembershipplans request=", request);

  return request;
}

async function purchaseMembershipPlan(token, product) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  let request = await axios.post(
    `${base_url}/wp-json/wpdating/v1/payment/checkout/stripe`,
    { token, product },
    config
  );

  console.log("getmembershipplans request=", request);

  return request;
}

function getMembershipPlanDetails(membershipId) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  let request = axios.get(
    `${base_url}/wp-json/wpdating/v1/membership/${membershipId}`,
    config
  );

  return request;
}

function getNearMe(lat, lng, page) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const body = {
    lat: `${lat}`,
    lng: `${lng}`,
  };

  console.log("latt", body);
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  let request = axios.post(
    `${base_url}/wp-json/api/v1/near-me?page=${page}`,
    body,
    config
  );

  return request;
}

function updateNearMeSettings(unit, value) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const body = {
    unit: unit,
    value: value,
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  let request = axios.post(
    `${base_url}/wp-json/api/v1/set/location`,
    body,
    config
  );

  return request;
}

function getNearMeSettings() {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  let request = axios.get(`${base_url}/wp-json/api/v1/get/location`, config);

  return request;
}

function checkFriendStatus(friend_uid) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  let request = axios.get(
    `${base_url}/wp-json/api/v1/friends/check/${friend_uid}`,
    config
  );

  return request;
}

function checkFeatureAccess(feature) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const body = {
    feature: feature,
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  let request = axios.post(
    `${base_url}/wp-json/wpdating/v1/feature_access`,
    body,
    config
  );

  return request;
}

function getMessageUsers(page = 1) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const request = axios.get(
    `${base_url}/wp-json/api/v1/chats/get/users?page=${page}`,
    config
  );

  return request;
}

function getMessages(userId, createdAt = "") {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const request = axios.get(
    `${base_url}/wp-json/api/v1/chats/get/messages?chat_user_id=${userId}`,
    config
  );

  return request;
}

function sendMessage(userId, message, deviceMessageId) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const body = {
    receiver_id: userId,
    message: message,
    type: "TEXT",
  };

  const request = axios.post(
    `${base_url}/wp-json/api/v1/chats/send/message`,
    body,
    config
  );

  return request;
}

function loginWithFacebook(fbToken) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
    },
  };

  const body = {
    fb_token: fbToken,
  };

  const request = axios.post(
    `${base_url}/wp-json/wpdating/v1/facebook/login`,
    body,
    config
  );

  return request;
}

function invalidateFacebookLogin(fbToken, accessToken) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const request = axios.delete(
    `https://graph.facebook.com/v5.0/${fbToken}/permissions`,
    config
  );

  return request;
}

// function checkVersion() {
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//       "X-Requested-With": "XMLHttpRequest",
//       "Access-Control-Allow-Origin": "*",
//     },
//   };

//   const request = axios.get(
//     `${base_url}/wp-json/wpdating/v1/app/version`,
//     config
//   );

//   return request;
// }

//country state city

function getCountries(membershipId) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  let request = axios.get(`${base_url}/wp-json/api/v1/countries1`, config);

  return request;
}

function getStates(countryId) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  let request = axios.get(
    `${base_url}/wp-json/api/v1/states?country_id=${countryId}`,
    config
  );

  return request;
}

function getCities(countryId, stateId) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  let request = axios.get(
    `${base_url}/wp-json/api/v1/city?country_id=${countryId}&state_id=${stateId}`,
    config
  );

  return request;
}

function getReceivedGifts() {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  let request = axios.get(
    `${base_url}/wp-json/api/v1/user/received/gifts`,
    config
  );

  console.log("getReceivedGifts request=", request);

  return request;
}

function getSentGifts() {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  let request = axios.get(`${base_url}/wp-json/api/v1/user/sent/gifts`, config);

  console.log("getSentGifts request=", request);

  return request;
}

function viewedMe() {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  let request = axios.get(`${base_url}/wp-json/api/v1/viewed-me`, config);

  console.log("getSentGifts request=", request);

  return request;
}

function iViewed() {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  let request = axios.get(`${base_url}/wp-json/api/v1/i-viewed`, config);

  console.log("getSentGifts request=", request);

  return request;
}

function receivedWinks() {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  let request = axios.get(`${base_url}/wp-json/api/v1/user/winks`, config);

  console.log("getSentGifts request=", request);

  return request;
}

function sendGift(idUser, giftId) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  console.log("send gifts token", accessToken);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const body = {};
  let request = axios.post(
    `${base_url}/wp-json/api/v1/gifts?user_id=${idUser}&gift_id=${giftId}`,
    body,
    config
  );

  console.log("sending gift", config);

  return request;
}

function getGifts() {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  console.log("get gifts token", accessToken);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  let request = axios.get(`${base_url}/wp-json/api/v1/gifts`, config);

  console.log("getallGifts request=", request);

  return request;
}

function getFlirts() {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  console.log("get gifts token", accessToken);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  let request = axios.get(`${base_url}/wp-json/api/v1/flirts`, config);

  console.log("getallFlirts request=", request);

  return request;
}

function sendWink(idUser, winkId) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

  console.log("send winks token", accessToken);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const body = new FormData();
  body.append("wink_id", winkId);
  body.append("user_id", idUser);

  let request = axios.post(
    `${base_url}/wp-json/api/v1/user/winks`,
    body,
    config
  );

  console.log("sending gift", config);

  return request;
}

async function getMyGallery() {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const request = await axios.get(
    `${base_url}/wp-json/api/v1/user/gallery/photos/me`,
    config
  );
  console.log("getProfileGallery request=", request);
  return request;
}

async function getUserGallery(userId) {
  const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const request = await axios.get(
    `${base_url}/wp-json/api/v1/user/${userId}/gallery/photos`,
    config
  );
  console.log("getProfileGallery request=", request);
  return request;
}
