import React, { Component } from "react";
import { default as LOCALE } from "../../../localization";
import { userConstants } from "../../../constants/userConstants";

class MultipleChoiceQuestion extends Component {
  constructor(props) {
    super(props);

    //initialization
    const {
      id: profile_setup_id,
      name,
      options,
      selected_question_option_ids,
      required,
    } = this.props.question;

    if (
      required === "Y" &&
      (!selected_question_option_ids ||
        selected_question_option_ids.length === 0)
    ) {
      this.props.onInputError(profile_setup_id, true);
      this.state = { inputError: true };
    } else {
      this.state = { inputError: false };
    }

    const answers = [];

    //converting ids to indices
    const selected_question_option_indices = [];
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      answers.push(option.option_value);
      if (
        // selected_question_option_ids &&
        // selected_question_option_ids.includes(option.question_option_id)
        option.selected === true
      ) {
        selected_question_option_indices.push(i);
      }
    }
    this.question = {
      selected_question_option_indices,
      name,
      answers,
      required,
    };

    this.options = {};

    //populating initial answers
    options
      .filter(
        (option) =>
          selected_question_option_ids &&
          selected_question_option_ids.includes(option.question_option_id)
      )
      .forEach((option) => {
        this.options[option.question_option_id] = option;
      });
  }

  onAnswerChanged = (selectedIndex, checked) => {
    const {
      id: profile_setup_id,
      field_type_id,
      options,
      required,
    } = this.props.question;
    const { question_option_id, option_value } = options[selectedIndex];

    const option = { question_option_id, option_value };

    if (checked) {
      this.options[question_option_id] = option;
    } else {
      delete this.options[question_option_id];
    }

    const optionsArray = Object.values(this.options);

    if (required === "Y" && (!optionsArray || optionsArray.length === 0)) {
      this.setState({ inputError: true });
      this.props.onInputError(profile_setup_id, true);
    } else {
      this.setState({ inputError: false });
      this.props.onInputError(profile_setup_id, false);
    }

    this.props.onAnswerChanged(profile_setup_id, {
      field_type_id,
      profile_setup_id,
      options: optionsArray,
    });
  };

  render() {
    LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
    console.log("render()");
    const { name, answers, selected_question_option_indices, required } =
      this.question;
    const { inputError } = this.state;

    return (
      <div className="question-multiple">
        {inputError && (
          <div>
            {name} {LOCALE.is_required_select}
          </div>
        )}
        <label>
          {name}
          {required === "Y" ? "*" : ""}
        </label>
        <div>
          {answers.map((answer, i) => (
            <div className="questions-1" key={i}>
              <input
                className="question-check"
                type="checkbox"
                name={answer}
                defaultChecked={selected_question_option_indices.includes(i)}
                onChange={(e) => {
                  this.onAnswerChanged(e.target.value, e.target.checked);
                }}
                value={i}
              />
              {answer}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default MultipleChoiceQuestion;
