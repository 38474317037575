import { winksConstants } from "../constants/winksConstants";

const initialState = {
  getWinksList: {
    gettingWinks: false,
    invalidData: false,
    internalError: false,
    receivedwinks: [],
    nextPage: 0,
    errorMessage: "",
  },

  getFlirtsList: {
    gettingFlirts: false,
    invalidData: false,
    internalError: false,
    flirts: [],
    nextPage: 0,
    errorMessage: "",
  },

  sendWinks: {
    gettingWinks: false,
    invalidData: false,
    internalError: false,
    sentwink: [],
    nextPage: 0,
    errorMessage: "",
  },
};

const getReceivedWinksListReducer = (
  state = initialState.getWinksList,
  action
) => {
  switch (action.type) {
    case winksConstants.FETCH_RECEIVED_WINKS_LISTS_REQUEST:
      if (action.reset) {
        return { ...initialState.getWinksList, gettingWinks: true };
      }
      return { ...state, gettingWinks: true };
    case winksConstants.FETCH_RECEIVED_WINKS_LISTS_SUCCESS:
      let { receivedwinks } = state;
      if (action.reset) {
        receivedwinks = [];
      }
      receivedwinks = [];
      const newWinks = action.responseData;
      console.log("error69", action);
      //   const { pagination } = action.responseData.meta;
      for (let i = 0; i < newWinks.length; i++) {
        receivedwinks.push(newWinks[i]);
      }
      console.log("getFriendsLists received winks=", receivedwinks);
      return {
        ...state,
        receivedwinks,
        totalWinks: receivedwinks.length,
        gettingWinks: false,
        // page: pagination.current_page,
        // lastPage: pagination.current_page === pagination.total_pages,
      };
    case winksConstants.FETCH_RECEIVED_WINKS_LISTS_FAILURE:
      switch (action.errorCode) {
        case 400:
          return {
            ...initialState.getWinksList,
            invalidData: true,
            errorMessage: action.errorMessage,
          };
        default:
          return {
            ...initialState.getWinksList,
            internalError: true,
          };
      }
    default:
      return state;
  }
};

const getAllFlirtsReducer = (state = initialState.getFlirtsList, action) => {
  switch (action.type) {
    case winksConstants.FETCH_ALL_FLIRTS_REQUEST:
      if (action.reset) {
        return { ...initialState.getFlirtsList, gettingFlirts: true };
      }
      return { ...state, gettingFlirts: true };
    case winksConstants.FETCH_ALL_FLIRTS_SUCCESS:
      let { flirts } = state;
      if (action.reset) {
        flirts = [];
      }

      const newFlirts = action.responseData;
      console.log("error69", action);
      //   const { pagination } = action.responseData.meta;
      for (let i = 0; i < newFlirts.length; i++) {
        flirts.push(newFlirts[i]);
      }
      console.log("all flirts list=", flirts);
      return {
        ...state,
        flirts,
        totalFlirts: flirts.length,
        gettingFlirts: false,
        // page: pagination.current_page,
        // lastPage: pagination.current_page === pagination.total_pages,
      };
    case winksConstants.FETCH_ALL_FLIRTS_FAILURE:
      switch (action.errorCode) {
        case 400:
          return {
            ...initialState.getFlirtsList,
            invalidData: true,
            errorMessage: action.errorMessage,
          };
        default:
          return {
            ...initialState.getFlirtsList,
            internalError: true,
          };
      }
    default:
      return state;
  }
};

const sendWinkReducer = (state = initialState.sendWinks, action) => {
  switch (action.type) {
    case winksConstants.SEND_WINK_REQUEST:
      if (action.reset) {
        return { ...initialState.sendWinks, gettingWinks: true };
      }
      return { ...state, gettingWinks: true };
    case winksConstants.SEND_WINK_SUCCESS:
      let { sentwink } = state;
      if (action.reset) {
        sentwink = [];
      }

      const newWinks = action.responseData;
      console.log("error69", action);
      //   const { pagination } = action.responseData.meta;

      sentwink.push(newWinks);

      console.log("sent wink response", sentwink);
      return {
        ...state,
        sentwink,
        totalWinks: sentwink.length,
        gettingWinks: false,
        // page: pagination.current_page,
        // lastPage: pagination.current_page === pagination.total_pages,
      };
    case winksConstants.SEND_WINK_FAILURE:
      switch (action.errorCode) {
        case 400:
          return {
            ...initialState.sendWinks,
            invalidData: true,
            errorMessage: action.errorMessage,
          };
        default:
          return {
            ...initialState.sendWinks,
            internalError: true,
          };
      }
    default:
      return state;
  }
};

export { getReceivedWinksListReducer, getAllFlirtsReducer, sendWinkReducer };
