export const membershipPlansConstants = {
    GET_MEMBERSHIP_PLANS_REQUEST: 'GET_MEMBERSHIP_PLANS_REQUEST',
    GET_MEMBERSHIP_PLANS_SUCCESS: 'GET_MEMBERSHIP_PLANS_SUCCESS',
    GET_MEMBERSHIP_PLANS_FAILURE: 'GET_MEMBERSHIP_PLANS_FAILURE',

    GET_MEMBERSHIP_PLANS_DETAIL_REQUEST: 'GET_MEMBERSHIP_PLANS_DETAIL_REQUEST',
    GET_MEMBERSHIP_PLANS_DETAIL_SUCCESS: 'GET_MEMBERSHIP_PLANS_DETAIL_SUCCESS',
    GET_MEMBERSHIP_PLANS_DETAIL_FAILURE: 'GET_MEMBERSHIP_PLANS_DETAIL_FAILURE',

    PURCHASE_MEMBERSHIP_PLANS_REQUEST: 'PURCHASE_MEMBERSHIP_PLANS_REQUEST',
    PURCHASE_MEMBERSHIP_PLANS_SUCCESS: 'PURCHASE_MEMBERSHIP_PLANS_SUCCESS',
    PURCHASE_MEMBERSHIP_PLANS_FAILURE: 'PURCHASE_MEMBERSHIP_PLANS_FAILURE'
};
